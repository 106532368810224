import React, { useState, useEffect } from "react";
import { FormGroup, Button, FormField } from "semantic-ui-react";

type ButtonProps = {
  handleSaveClick: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    trigger: String
  ) => void;
  handleResetClick: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  trigger: String;
};

function Buttons({ handleSaveClick, handleResetClick, trigger }: ButtonProps) {
  return (
    <FormGroup widths="equal" style={{ marginTop: 3 + "%" }}>
      <FormField>
        <Button
          icon="plus"
          labelPosition="left"
          color="linkedin"
          fluid={true}
          size="big"
          type="submit"
          id="submit"
          name="submit"
          content="Save"
          onClick={(e) => {
            handleSaveClick(e, trigger);
          }}
        ></Button>
      </FormField>
      <FormField>
        <Button
          color="grey"
          fluid={true}
          size="big"
          type="button"
          id="clear"
          name="clear"
          icon="redo"
          labelPosition="left"
          content="Clear"
          onClick={handleResetClick}
        ></Button>
      </FormField>
    </FormGroup>
  );
}

export default Buttons;
