import React from "react";
import { Dimmer, Image, Loader } from "semantic-ui-react";
function Customspinner(props: any) {
  return (
    <>
      <Dimmer active inverted>
        <Loader inverted>{props.text}</Loader>
      </Dimmer>
    </>
  );
}
export default Customspinner;
