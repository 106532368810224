import { CustomerDetail } from "./dbobjectmodel";

export const Validatescheme = {
  entrynumber: "",
  schemename: "",
  installmentamount: "",
  yellowgoldwastage: "",
  antiqgoldwastage: "",
  diamondmc: "",
  diamondcent: "",
  silvermc: "",
  giftarticlemrp: "",
  code: "",
};

export const ValidateCustomer = {
  customername: "",
  cardnumber: "",
  aadharnumber: "",
  branch: "",
  doornumber: "",
  street: "",
  pincode: "",
  customermobilenumber: "",
  area: "",
  taluk: "",
  city: "",
  state: "",
  customeremail: "",
  nomineename: "",
  relationship: "",
  nomineemobilenumber: "",
  // schmemename:"",
  // schemeamount:"",
  // groupserialnumber:"",
  // groupname:"",
};

export const ValidateConcern = {
  companycode: "",
  companyname: "",
  companytype: "",
  ownerpartnername: "",
  doornumber: "",
  street: "",
  area: "",
  taluk: "",
  city: "",
  state: "",
  country: "India",
  pincode: "",
  mobilenumber: "",
  landlinenumber: "",
  faxnumber: "",
  gstn: "",
  pan: "",
  msme: "",
};

export const ValidateUserRoles = {
  concern: "",
  userrole: "",
};

export const ValidateChitGift = {
  cardnumber: "",
  gifttype: "",
  barcode: "",
};

export const Validate = <T, M>(validateObject: T, dataObject: M) => {
  let _isValid = false;
  const _validate: any = validateObject;
  const formInputs: any = dataObject;

  Object.keys(_validate).map((v) => {
    if (_validate[v] === "" && formInputs[v] === _validate[v]) {
      _isValid = true;
      return;
    }
    //     // if (v === "mobile" && T[v].length < 10) {
    //     //   _isValid = true;
    //     //   return;
    //     // }
    //     //   if (!Utility.validateEmail(T.email1)) {
    //     //     _isValid = true;
    //     //     return;
    //     //   }
    //     //   if (!Utility.validateGSTIN(T.gstnnumber)) {
    //     //     _isValid = true;
    //     //     return;
    //     //   }
  });
  return _isValid;
};
