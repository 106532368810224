import React, { useEffect, useState } from "react";
import {
  FormInput,
  FormGroup,
  Form,
  Button,
  FormField,
  Container,
  Checkbox,
  TextArea,
  Icon,
  FormSelect,
} from "semantic-ui-react";
import { ToastContainer } from "react-toastify";
import { useLocation } from "react-router-dom";
import { EmployeDetails } from "../lib/dbobjectmodel.ts";
import { EmployeInitialState } from "../lib/initialState.ts";

function EmployeDetail(props: any) {
  let { state } = useLocation();
  const [EmployeDetail, setEmployeDetail] =
    useState<EmployeDetails>(EmployeInitialState);

  useEffect(() => {
    props.onTrigger(process.env.REACT_APP_GET_URL_EMPLOYE);
  }, []);

  useEffect(() => {
    if (state) {
      let _data: EmployeDetails = JSON.parse(state?.data);
      setTimeout(() => {
        setEmployeDetail(_data);
      }, 500);
    }
  }, [state?.data]);

  return (
    <div className="content">
      <Container className="project-form">
        <Form>
          <FormGroup widths="equal">
            <FormInput
              type="text"
              value={EmployeDetail?.branchname}
              id="branch"
              name="branch"
              fluid={true}
              label="Branch"
              placeholder="Branch"
            />
            <FormInput
              type="number"
              id="ecno"
              value={EmployeDetail?.ecno}
              name="ecno"
              fluid={true}
              label="EC Number"
              placeholder="EC Number"
              maxLength="50"
            />
          </FormGroup>
          <FormGroup widths="equal">
            <FormInput
              type="text"
              fluid={true}
              label="Employe Name"
              value={EmployeDetail?.employename}
              placeholder="Employe Name"
              id="employename"
              name="employename"
              maxLength="150"
            />
            <FormInput
              type="text"
              id="department"
              value={EmployeDetail?.department}
              name="department"
              fluid={true}
              label="Department"
              placeholder="Department"
              maxLength="150"
            />
          </FormGroup>
          <FormGroup widths="equal">
            <FormInput
              type="text"
              fluid={true}
              label="Division"
              value={EmployeDetail?.division}
              placeholder="Division"
              id="division"
              name="division"
              maxLength="150"
            />

            <FormInput
              type="text"
              fluid={true}
              label="Designation"
              value={EmployeDetail?.designation}
              placeholder="Designation"
              id="designation"
              name="designation"
              maxLength="150"
            />
          </FormGroup>
          <FormGroup widths="equal">
            <FormInput
              type="text"
              fluid={true}
              label="Company Name"
              value={EmployeDetail?.companyname}
              placeholder="Company Name"
              id="companyname"
              name="companyname"
              maxLength="150"
            />

            <FormInput
              type="text"
              fluid={true}
              label="Yearcode"
              value={EmployeDetail?.yearcode}
              placeholder="Yearcode"
              id="Yearcode"
              name="Yearcode"
              maxLength="150"
            />
          </FormGroup>
        </Form>
        <ToastContainer />
      </Container>
    </div>
  );
}
export default EmployeDetail;
