import React, { useState, useEffect, useContext } from "react";
import Buttons from "../components/Buttons.tsx";
import { HttpGet, HttpTrigger, useFetch } from "../lib/useFetch.tsx";
import Customspinner from "../components/Customspinner.tsx";
import { Result, useGlobalContext } from "../lib/types.ts";
import { SuccessToast, ErrorToast } from "../utils/ProjectToast.js";
import { useNavigate, useLocation } from "react-router-dom";
import { Utility } from "../utils/Utility.js";
import {
  Validate,
  ValidateChitGift,
  ValidateConcern,
  ValidateCustomer,
  Validatescheme,
  ValidateUserRoles,
} from "../lib/formvalidation.ts";
import { Dropdowns, EmployeDetails } from "../lib/dbobjectmodel.ts";
import { EmployeInitialState } from "../lib/initialState.ts";

function Master({ component: Component, props: Props }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [inputData, setData] = useState<any>();
  const [isReset, setIsReset] = useState<Boolean>(false);
  const [trigger, setTrigger] = useState<string>("");
  const [spinners, setspinners] = useState<boolean>(false);
  const [isDataSaving, setIsDataSaving] = useState<boolean>(false);
  const [isValid, setisValid] = useState(false);
  const [isFormatted, setFormatted] = useState<string>("");
  const { setSharedData, employeeInfo } = useContext(useGlobalContext);
  const [employee, setEmployee] = useState<EmployeDetails>(EmployeInitialState);

  const { status, statusText, data, error, loading } = useFetch<Dropdowns[]>(
    process.env.REACT_APP_DROPDOWNS,
    [],
    undefined
  );

  useEffect(() => {
    if (employeeInfo == null || Object.keys(employeeInfo).length === 0) {
      ErrorToast("Session expired, please login again!");
      setTimeout(() => {
        navigate("/");
      }, 1000);
    }
  }, []);

  useEffect(() => {
    setSharedData(data);
    setEmployee(employeeInfo);
  }, [status == 200]);

  const handleProcessData = (inputData: any) => {
    setData(inputData);
  };
  const onTrigger = (trigger: string) => {
    setTrigger(trigger);
  };

  const handleReset = () => {
    setIsReset(true);
  };

  const onResetState = (flag: boolean) => {
    setIsReset(flag);
    setisValid(false);
    setFormatted("");
  };

  const handleSave = () => {
    let _FormValidation: any;
    let mesage: string = "";

    if (location.pathname.includes("schemes")) {
      _FormValidation = Validatescheme;
    } else if (location.pathname.includes("customerdetails")) {
      _FormValidation = ValidateCustomer;
    } else if (location.pathname.includes("addconcern")) {
      _FormValidation = ValidateConcern;
    } else if (location.pathname.includes("concernroles")) {
      _FormValidation = ValidateUserRoles;
    } else if (location.pathname.includes("chitgift")) {
      _FormValidation = ValidateChitGift;
    }

    if (Validate(_FormValidation, inputData)) {
      ErrorToast("Please fill all mandatory fields");
      setisValid(true);
      return;
    } else if (location.pathname.includes("customerdetails")) {
      if (inputData?.aadharnumber.length < 12) {
        mesage += "Please enter valid AADHAR number! \n";
      }
      if (inputData?.pincode.length < 6) {
        mesage += "Please enter valid pincode! \n";
      }
      if (inputData?.customermobilenumber.length < 10) {
        mesage += "Please enter valid mobile number! \n";
      }
      if (!Utility.validateEmail(inputData?.customeremail)) {
        mesage += "Please enter valid email! \n";
      }
      if (inputData?.nomineemobilenumber.length < 10) {
        mesage += "Please enter valid nominee mobile number! \n";
      }
    } else if (location.pathname.includes("addconcern")) {
      if (inputData?.pincode.length < 6) {
        mesage += "Please enter valid pincode! \n";
      }
      if (inputData?.mobilenumber.length < 10) {
        mesage += "Please enter valid mobile number! \n";
      }
      if (inputData?.landlinenumber.length < 10) {
        mesage += "Please enter valid Landline number! \n";
      }
      if (
        inputData?.gstn.length < 15 &&
        !Utility.validateGSTIN(inputData?.gstn)
      ) {
        mesage += "Please enter valid GSTN number! \n";
      }

      if (inputData?.pan.length < 10 && !Utility.validatepan(inputData?.pan)) {
        mesage += "Please enter valid PAN number! \n";
      }
      if (inputData?.tan != "" && inputData?.tan.length < 10) {
        mesage += "Please enter valid TAN number! \n";
      }
      if (
        inputData?.cin != "" &&
        inputData?.cin.length < 21 &&
        !Utility.validatecin(inputData?.cin)
      ) {
        mesage += "Please enter valid CIN number! \n";
      }
    }
    if (mesage.length > 6) {
      ErrorToast(mesage);
      setFormatted(mesage);
      return;
    } else {
      inputData.CreatedBy = employee.ecno;
      inputData.ModifiedBy = employee.ecno;
      inputData.deletedBy = employee.ecno;

      setisValid(false);
      setspinners(true);
      HttpTrigger(
        trigger,
        inputData?.insert_update == 1 ? "POST" : "PUT",
        inputData
      ).then((res: Result) => {
        setTimeout(() => {
          setspinners(false);
        }, 1000);
        if (res.message === "success") {
          SuccessToast("Details saved successfully!");
          HttpGet(process.env.REACT_APP_DROPDOWNS, undefined).then(
            (res: any) => {
              setTimeout(() => {
                const data: Dropdowns[] = res;
                setSharedData(data);
              }, 500);
            }
          );
          if (inputData?.insert_update > 1) {
            setTimeout(() => {
              navigate(-1);
            }, 3500);
          }
        } else {
          ErrorToast("Something went wrong, please try again after sometime!");
        }
        setFormatted("");
      });
    }
  };

  return (
    <>
      {!spinners ? (
        <>
          <Component
            {...Props}
            onProcessdata={handleProcessData}
            onReset={onResetState}
            onTrigger={onTrigger}
            handleResetClick={handleReset}
            handleSaveClick={handleSave}
            button={Buttons}
            reset={isReset}
            isDataSaving={isDataSaving}
            isValid={isValid}
            isFormatted={isFormatted}
          />
        </>
      ) : (
        <Customspinner text="Saving..."></Customspinner>
      )}
    </>
  );
}

export default Master;
