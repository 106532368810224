import React, { useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Chits from "./views/chits.tsx";
import AdminLayout from "./layouts/applayout.js";
import { ToastContainer } from "react-toastify";

const Main = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Chits />} />
          <Route path="/admin/*" key={1} element={<AdminLayout />} />
          {/* <Route path="/" element={<Navigate to="/dashboard" replace />} /> */}
        </Routes>
      </BrowserRouter>
      {/* <ToastContainer /> */}
    </>
  );
};

export default Main;
