import React, { useState, useEffect, useRef, useContext } from "react";
import {
  TableRow,
  TableHeaderCell,
  TableHeader,
  TableCell,
  TableBody,
  Header,
  Table,
  Button,
  Icon,
  Segment,
  Label,
  Popup,
  FormGroup,
  FormField,
  FormInput,
} from "semantic-ui-react";
import {
  Card,
  CardBody,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

// import Skeleton from "react-loading-skeleton";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { CustomWhatsappMessages } from "../../utils/whatsapp.js";
import { SuccessToast, ErrorToast } from "../../utils/ProjectToast.js";
import { ToastContainer } from "react-toastify";
import Customspinner from "../Customspinner.tsx";
import { HttpTrigger } from "../../lib/useFetch.tsx";
import { Result, useGlobalContext } from "../../lib/types.ts";
import { ValidateCustomer } from "../../lib/formvalidation.ts";
import { EmployeDetails } from "../../lib/dbobjectmodel.ts";
import { EmployeInitialState } from "../../lib/initialState.ts";

function CustomTable({
  headers,
  datas = [],
  isedit,
  isdelete,
  iswhatapp,
  issms,
  iscustomer,
  isapprove = false,
  isdefault = true,
  isfilter = false,
  navigateurl = "",
  deleteurl = "",
  dbtable = "",
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const [isDone, setIsDone] = useState(false);
  const [respStatus, setRespStatus] = useState(true);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState<any>();
  const [spinners, setspinners] = useState(false);
  const [txtFilter, setTxtFilter] = useState("");
  const [fullData, setFullData] = useState(datas);
  const [currentData, setCurrentData] = useState(datas);
  const [isSearch, setIsSearch] = useState(false);
  const { employeeInfo } = useContext(useGlobalContext);
  const [delIndex, setDelIndex] = useState();
  const [employee, setEmployee] = useState<EmployeDetails>(EmployeInitialState);

  useEffect(() => {
    setFullData(datas);
    setCurrentData(datas);
    setEmployee(employeeInfo);
  }, []);

  const editData = (data) => {
    navigate(navigateurl, {
      state: { data: JSON.stringify(data) },
    });
  };
  const commonDelete = (data, idx) => {
    setDeleteId(data);
    setDelIndex(idx);
    setOpen(true);
  };
  const confirmDelete = () => {
    setOpen(false);
    setspinners(true);
    deleteId.insert_update = 3;
    deleteId.Isdeleted = 1;
    deleteId.deletedBy = employee.ecno;
    HttpTrigger(deleteurl, "DELETE", deleteId).then((res: Result) => {
      setTimeout(() => {
        setspinners(false);
      }, 500);
      if (res.message === "success") {
        SuccessToast("Deleted successfully!");
        datas[delIndex] = { ...datas[delIndex], isdeleted: "No" };
      } else {
        ErrorToast("Something went wrong, please try again after sometime!");
      }
    });
  };

  const Close = () => {
    setOpen(false);
  };
  let my = 0;
  const whatappnavigate = async (data) => {
    setIsDone(true);
    setTimeout(() => {
      const _files = [];
      if (Object.keys(data).length === 16) {
        Object.keys(data).map((k, idx) => {
          if (k.indexOf("Path") > 0 && data[k] !== "") {
            _files.push({
              filename: data[`${k.replace("Path", "")}Name`],
              filepath: data[k],
            });
          }
        });
      } else if (Object.keys(data).length === 32) {
        Object.keys(data).map((k, idx) => {
          if (k.indexOf("Path") > 0 && data[k] !== "") {
            _files.push({
              filename: data[`${k.replace("Path", "")}`],
              filepath: data[k],
            });
          }
        });
      }
      _files.map(async (fi) => {
        CustomWhatsappMessages.sendTemplateDocument(
          data.mobile,
          fi.filepath,
          fi.filename,
          fi.filename
        ).then((d) => {
          console.log(my);
          if (d !== "success") {
            setRespStatus(false);
            if (my == 0) {
              ErrorToast(
                `Please add this mobile number ${data.mobile} into meta account and try again to send document`
              );
              setIsDone(false);
            }
          } else if (my === _files.length - 1) {
            SuccessToast("Notification Sent");
            setIsDone(false);
          }
          my += 1;
        });
      });
    }, 1000);
  };

  const sendSMS = async (data) => {
    setIsDone(true);
    setTimeout(() => {
      CustomWhatsappMessages.sendTextMessage(
        data.mobile,
        `Dear Customer, remainder about payment due : ${data.payment}`
      )
        .then((d) => {
          if (d !== "success") {
            setRespStatus(false);
            if (my == 0) {
              ErrorToast(
                `Please add this mobile number ${data.mobile} into meta account and try again to send document`
              );
              setIsDone(false);
            }
          } else {
            setTimeout(() => {
              SuccessToast("Notification Sent");
              setIsDone(false);
            }, 1000);
          }
        })
        .catch((err) => {
          setIsDone(false);
          ErrorToast(process.env.REACT_APP_ERROR_MESSAGE);
        });
    }, 1000);
  };

  const sendAcknowledge = async (data) => {
    const reqdata = data;
    reqdata.isacknowledged = true;
    const docId = data.id;
    delete reqdata["documentnames"];
    delete reqdata["id"];
    delete reqdata["customer"];
    setIsDone(true);
    // setTimeout(() => {
    //   ConnectDB.PostData(
    //     reqdata,
    //     process.env.REACT_APP_DB_CUSTOMER_REQUEST,
    //     "Update",
    //     docId
    //   )
    //     .then((res) => {
    //       SuccessToast("Updated successfully!");
    //       setIsDone(false);
    //       let cnt = sessionStorage.getItem("reqcount");
    //       sessionStorage.setItem("reqcount", cnt > 0 ? cnt - 1 : 0);
    //       setTimeout(() => {
    //         window.location.reload();
    //       }, 500);
    //     })
    //     .catch((err) => {
    //       ErrorToast(process.env.REACT_APP_ERROR_MESSAGE);
    //       setIsDone(false);
    //     });
    // }, 1000);
  };

  useEffect(() => {
    if (txtFilter === "") {
      setCurrentData(datas);
    } else {
      let _local = [];
      const _data = structuredClone(datas);
      _data.filter((d) => {
        if (Object.values(d).toString().toLowerCase().indexOf(txtFilter) >= 0) {
          _local.push(d);
        }
      });
      setCurrentData(_local);
    }
  }, [txtFilter]);

  return (
    <>
      <Row>
        <Col md="12">
          <Card>
            <CardBody>
              {isfilter ? (
                <FormGroup>
                  <FormField>
                    <FormInput
                      fluid={true}
                      placeholder="Search..."
                      id="myfilter"
                      name="myfilter"
                      value={txtFilter}
                      onChange={(e) => {
                        setIsSearch(true);
                        setTxtFilter(e.target.value);
                      }}
                    />
                  </FormField>
                </FormGroup>
              ) : (
                ""
              )}
              <Table celled size="small" color="teal">
                <TableHeader>
                  <TableRow>
                    {
                      headers
                        ? headers.map((header) => {
                            return !header.ishide ? (
                              <TableHeaderCell width={50}>
                                {" "}
                                {header.text}
                              </TableHeaderCell>
                            ) : (
                              ""
                            );
                          })
                        : ""
                      // <Skeleton count={5} />
                    }
                    {isedit || isdelete ? (
                      <TableHeaderCell textAlign="center">
                        Action
                      </TableHeaderCell>
                    ) : (
                      ""
                    )}
                    {iswhatapp ? (
                      <TableHeaderCell textAlign="center">
                        WhatsApp
                      </TableHeaderCell>
                    ) : (
                      ""
                    )}
                    {issms ? (
                      <TableHeaderCell textAlign="center">SMS</TableHeaderCell>
                    ) : (
                      ""
                    )}
                    {isapprove ? (
                      <TableHeaderCell textAlign="center">
                        Acknowledge
                      </TableHeaderCell>
                    ) : (
                      ""
                    )}
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {(!isSearch ? datas || [] : currentData || []).map(
                    (data, idx) => {
                      return (
                        <TableRow key={data.id} verticalAlign="top">
                          {headers.map((h, idx) => {
                            return !h.ishide ? (
                              <TableCell
                                className={
                                  h.text == "GST Notice" ? "flash" : ""
                                }
                              >
                                {!h.islink ? (
                                  h.text == "Payment" || h.text == "Amount" ? (
                                    <Label ribbon color="blue">
                                      <h4>
                                        <Icon name="rupee" />
                                        {data[h.value]}
                                      </h4>
                                    </Label>
                                  ) : h.text == "GST Notice" ? (
                                    <Icon name="attention">
                                      {" "}
                                      data[h.value]{" "}
                                    </Icon>
                                  ) : (
                                    data[h.value]
                                  )
                                ) : (
                                  <Popup
                                    content={data[h.value]}
                                    trigger={
                                      <a
                                        target="_blank"
                                        download={data[h.value]}
                                        rel="noopener noreferrer"
                                        href={data[h.path]}
                                        className="limited-text"
                                      >
                                        {h.text != "GST Notice" ? (
                                          data[h.value]
                                        ) : data[h.value] ? (
                                          <Icon color="red" name="attention">
                                            {data[h.value]}
                                          </Icon>
                                        ) : (
                                          ""
                                        )}
                                      </a>
                                    }
                                  />
                                )}
                              </TableCell>
                            ) : (
                              ""
                            );
                          })}
                          {isedit || isdelete ? (
                            <TableCell
                              textAlign="center"
                              verticalAlign="middle"
                            >
                              <Icon
                                link={true}
                                size="large"
                                name="edit outline"
                                onClick={() => {
                                  editData(data);
                                }}
                                className={!isedit ? "d-n" : ""}
                              />
                              {isdelete ? (
                                <Icon
                                  style={{ marginLeft: 10 }}
                                  link={true}
                                  size="large"
                                  onClick={() => {
                                    commonDelete(data, idx);
                                  }}
                                  color="red"
                                  name="remove circle"
                                  className={!isdelete ? "d-n" : ""}
                                />
                              ) : (
                                ""
                              )}
                            </TableCell>
                          ) : (
                            ""
                          )}
                          {iswhatapp ? (
                            <TableCell
                              textAlign="center"
                              verticalAlign="middle"
                            >
                              <Button
                                loading={isDone}
                                icon
                                size="mini"
                                color="green"
                                disabled={isDone}
                                labelPosition="left"
                                onClick={() => {
                                  whatappnavigate(data);
                                }}
                              >
                                <Icon name="whatsapp" size="medium"></Icon>
                                Send Document
                              </Button>
                            </TableCell>
                          ) : (
                            ""
                          )}
                          {issms ? (
                            <TableCell
                              textAlign="center"
                              verticalAlign="middle"
                            >
                              <Button
                                icon
                                color="twitter"
                                loading={isDone}
                                disabled={isDone}
                                size="mini"
                                labelPosition="left"
                                onClick={() => {
                                  sendSMS(data);
                                }}
                              >
                                <Icon name="comment outline"></Icon>
                                Send Pay Remainder
                              </Button>
                            </TableCell>
                          ) : (
                            ""
                          )}
                          {isapprove ? (
                            <TableCell
                              textAlign="center"
                              verticalAlign="middle"
                            >
                              <Button
                                loading={isDone}
                                icon
                                color={
                                  data.isacknowledged ? "green" : "twitter"
                                }
                                size="small"
                                disabled={data.isacknowledged ? true : false}
                                labelPosition="left"
                                onClick={() => {
                                  sendAcknowledge(data);
                                }}
                              >
                                <Icon
                                  name={
                                    data.isacknowledged
                                      ? "hand point up"
                                      : "question circle outline"
                                  }
                                ></Icon>
                                Done
                              </Button>
                            </TableCell>
                          ) : (
                            ""
                          )}
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {isdefault ? (
        datas.length == 0 && !iscustomer ? (
          <Segment placeholder>
            <Header icon>
              <Icon name="pdf file outline" />
              No documents are listed for this customer.
            </Header>
            {sessionStorage.getItem("role") !== "Admin" ? (
              <Button
                primary
                onClick={() => {
                  navigate("/admin/paymentremainder");
                }}
              >
                Add Document
              </Button>
            ) : (
              ""
            )}
          </Segment>
        ) : datas.length == 0 ? (
          <Segment placeholder>
            <Header icon>
              <Icon name="pdf file outline" />
              No documents are uploaded.
            </Header>
          </Segment>
        ) : (
          ""
        )
      ) : (
        ""
      )}
      <ToastContainer />
      {spinners ? <Customspinner text="Deleting.." /> : ""}
      <Modal isOpen={open} backdrop={true}>
        <ModalHeader>Confirm Delete</ModalHeader>
        <ModalBody>Are you sure to delete?</ModalBody>
        <ModalFooter>
          <Button color="red" onClick={confirmDelete}>
            Yes
          </Button>{" "}
          <Button color="grey" onClick={Close}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default CustomTable;
