const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  currentYear = new Date().getFullYear();

export const Utility = {
  getdropdwon: (filter, data) => {
    const _dropdown = [];
    const _data = data.filter((d) => {});
    for (let m = 0; m < monthNames.length; m++) {
      let _monthYear = {};
      _monthYear.key = m;
      _monthYear.text = `${monthNames[m]} - ${currentYear}`;
      _monthYear.value = `${monthNames[m]} - ${currentYear}`;
      _dropdown.push(_monthYear);
    }
    return _dropdown;
  },
  getAllMonth: () => {
    const _months = [];
    for (let m = 0; m < monthNames.length; m++) {
      let _monthYear = {};
      _monthYear.key = m;
      _monthYear.text = `${monthNames[m]}`;
      _monthYear.value = `${monthNames[m]}`;
      _months.push(_monthYear);
    }
    return _months;
  },
  getCurrentYear: () => {
    return currentYear;
  },
  getFinancialYear: () => {
    const _fyear = [];
    const _year = currentYear - 2017;
    for (let m = _year; m > -1; ) {
      let _monthYear = {};
      _monthYear.key = m;
      _monthYear.text = `${currentYear}` - m;
      _monthYear.value = `${currentYear}` - m;
      _fyear.push(_monthYear);
      m--;
    }
    return _fyear;
  },

  adminMenu: [0, 1, 2, 3, 4, 5, 8, 10, 12, 13, 14, 15],
  employeeMenu: [0, 11],
  user: "user",

  validateEmail(email) {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  },

  validateGSTIN(gstin) {
    const gstinRegex =
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    return gstinRegex.test(gstin);
  },

validatepan(pan) {
  const panRegex =
    /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
  return panRegex.test(pan);
},

validatecin(cin) {
  const cinRegex =
    /'^([LUu]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})?$'/;
  return cinRegex.test(cin);
},


};
