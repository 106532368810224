import React, { createContext, ReactNode, useState } from "react";
import { useGlobalContext } from "./types.ts";
import { Dropdowns, EmployeDetails } from "./dbobjectmodel";

type MyProviderProps = {
  children: ReactNode; // ReactNode represents any valid React child (elements, strings, etc.)
};

export const MyProvider: React.FC<MyProviderProps> = ({ children }) => {
  const [sharedData, setData] = useState<Dropdowns[]>([]);
  const [employeeInfo, setEmployee] = useState<EmployeDetails>({});

  const setSharedData = (newData: Dropdowns[]) => {
    setData(newData);
  };

  const setEmployeeInfo = (newData: EmployeDetails) => {
    setEmployee(newData);
  };

  return (
    <useGlobalContext.Provider
      value={{ sharedData, setSharedData, employeeInfo, setEmployeeInfo }}
    >
      {children}
    </useGlobalContext.Provider>
  );
};
