import { Dropdowns } from "../lib/dbobjectmodel";

export const Gender = [
  { key: "m", text: "Male", value: "Male" },
  { key: "f", text: "Female", value: "Female" },
  { key: "u", text: "Other", value: "Other" },
];

export const Religion = [
  { key: "h", text: "Hindu", value: "Hindu" },
  { key: "c", text: "Christian", value: "Christian" },
  { key: "m", text: "Muslim", value: "Muslim" },
  { key: "u", text: "Other", value: "Other" },
];

export const Relationships = [
  { key: "h", text: "Husband", value: "Husband" },
  { key: "c", text: "Wife", value: "Wife" },
  { key: "m", text: "Daughter", value: "Daughter" },
  { key: "t", text: "Son", value: "Son" },
  { key: "u", text: "Other", value: "Other" },
];

export const Concerns = [
  {
    key: "Jewell",
    text: "Jewell",
    value: "Jewell",
    image: { avatar: true, src: "/images/avatar/small/jenny.jpg" },
  },

  {
    key: "Textile",
    text: "Textile",
    value: "Textile",
  },
];

export const Roles = [
  {
    key: "SuperAdmin",
    text: "SuperAdmin",
    value: "SuperAdmin",
  },

  {
    key: "Admin",
    text: "Admin",
    value: "Admin",
  },
];
export const CompanyType = [
  { key: "Limited", text: "Limited", value: "Limited" },
  { key: "LLp", text: "LLP", value: "LLP" },
  { key: "Firm", text: "Firm", value: "Firm" },
  { key: "Partnership", text: "Partnership", value: "Partnership" },
  { key: "Public", text: "Public", value: "Public" },
];

export const GiftType = [
  { key: "sts", text: "Single to Single", value: "sts" },
  { key: "stm", text: "Single to Multiple", value: "stm" },
  { key: "mts", text: "Multiple to Single", value: "mts" },
];

export const getDropdown = (filter: string, data: Dropdowns[]): Dropdowns[] => {
  const ddl: Dropdowns[] = data?.filter((d: Dropdowns) => {
    return d.type === filter;
  });

  const _dropdown: Dropdowns[] = [];
  for (let m = 0; m < ddl?.length; m++) {
    let _ddl: any = {};
    _ddl.key = ddl[m]?.key;
    _ddl.text = ddl[m]?.text;
    _ddl.value = ddl[m]?.key;
    _dropdown.push(_ddl);
  }
  return _dropdown;
};
