import {
  BranchDetail,
  ChitGiftDetail,
  ConcernRoleDetail,
  CustomerDetail,
  DashBoardCounts,
  EmployeDetails,
  PaymentDetail,
  type SchemeDetail,
} from "./dbobjectmodel";
import { type Concerns } from "./dbobjectmodel";
import { GroupDetail } from "../lib/dbobjectmodel";

const firstDay = new Date();

export const SchemeInitalState: SchemeDetail = {
  schemeid: 0,
  entrynumber: "",
  entrydate: firstDay.toISOString().split("T")[0],
  schemename: "",
  gift: false,
  luckydraw: false,
  weight: false,
  status: false,
  CreatedBy: 0,
  ModifiedBy: 0,
  isdeleted: false,
  deletedBy: 0,
  insert_update: 1,
  deletedDate: "",
  collectiontype: false,
  installmentamount: "",
  yellowgoldwastage: "",
  antiqgoldwastage: "",
  diamondmc: "",
  diamondcent: "",
  silvermc: "",
  giftarticlemrp: "",
  code: "",
};

export const ConcernInitialState: Concerns = {
  concernid: 0,
  companycode: "",
  companyname: "",
  companytype: "",
  ownerpartnername: "",
  yearcode: "",
  doornumber: "",
  street: "",
  area: "",
  taluk: "",
  city: "",
  state: "",
  country: "India",
  pincode: "",
  mobilenumber: "",
  landlinenumber: "",
  faxnumber: "",
  gstn: "",
  tan: "",
  cin: "",
  pan: "",
  msme: "",
  CreatedBy: 0,
  ModifiedBy: 0,
  Isdeleted: false,
  deletedBy: 0,
  deletedDate: null,
  insert_update: 1,
};

const today = new Date();
const firstDays = new Date(today.getFullYear(), today.getMonth(), 2);
const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 1);

export const GroupinitialState: GroupDetail = {
  serialnumber: 0,
  active: true,
  groupname: "",
  startdate: firstDays.toISOString().split("T")[0],
  enddate: lastDay.toISOString().split("T")[0],
  CreatedBy: 0,
  ModifiedBy: 0,
  Isdeleted: false,
  deletedBy: 0,
  insert_update: 1,
  deletedDate: null,
  installmentamount: 0,
  branchname: "",
};

export const CustomerInitialState: CustomerDetail = {
  customername: "",
  cardnumber: "",
  aadharnumber: "",
  branch: "",
  gender: "",
  religion: "",
  occupation: "",
  birthdate: "",
  anniversarydate: "",
  doornumber: "",
  street: "",
  pincode: "",
  customermobilenumber: "",
  area: "",
  taluk: "",
  city: "",
  state: "",
  customeremail: "",
  employeeid: "",
  nomineename: "",
  relationship: "",
  nomineemobilenumber: "",
  CreatedBy: 0,
  ModifiedBy: 0,
  Isdeleted: false,
  deletedBy: 0,
  insert_update: 1,
  deletedDate: null,
  schemeid: "",
  schemename: "",
  schemeamount: "",
  groupserialnumber: "",
  groupname: "",
  startdate: firstDays.toISOString().split("T")[0],
  enddate: lastDay.toISOString().split("T")[0],
  customerid: 0,
};

export const ChitGiftInitialState: ChitGiftDetail = {
  cardnumber: "",
  groupnumber: "",
  cusname: "",
  barcode: "",
  groupname: "",
  giftvalue: "",
  installment: 0,
  gifttype: "",
  CreatedBy: 0,
  ModifiedBy: 0,
  isdeleted: false,
  deletedBy: 0,
  insert_update: 1,
  giftid: 0,
};

export const PaymentInitialState: PaymentDetail = {
  entryno: "",
  cardnumber: "",
  grpname: "",
  grpnumber: "",
  installmentamount: 0,
  installmentweight: "",
  actualinstallment: "",
  maturitydate: "",
  recievedinstallment: "",
  pendinginstallment: "",
  cusname: "",
  doornumber: "",
  street: "",
  pincode: "",
  mobilenumber: "",
  area: "",
  taluk: "",
  city: "",
  state: "",
  cusmail: "",
  recievedby: "",
  creditcard: "",
  cash: "",
  bank: "",
  oldchit: "",
  CreatedBy: 0,
  ModifiedBy: 0,
  Isdeleted: false,
  deletedBy: 0,
  insert_update: 1,
  deletedDate: null,
};

export const EmployeInitialState: EmployeDetails = {
  ecno: "",
  employename: "",
  department: "",
  division: "",
  designation: "",
  yearcode: "",
};

export const ConcernUserInitialState: ConcernRoleDetail = {
  concernroleid: 0,
  concern: "",
  userrole: "",
  insert_update: 1,
  CreatedBy: 0,
  ModifiedBy: 0,
  isdeleted: "Yes",
  deletedBy: 0,
  deletedDate: null,
  concernname: "",
};

export const BranchInitialState: BranchDetail = {
  branchname: "",
  branchserialno: "",
  companyname: "",
  legalname: "",
  branchcode: "",
  branchdoornumber: 0,
  street: "",
  area: "",
  taluk: "",
  city: "",
  state: "",
  country: "",
  pincode: "",
  mobilenumber: 0,
  landlinenumber: 0,
  faxnumber: "",
  gstn: "",
  tan: "",
  cin: "",
  pan: "",
  msme: "",
};

export const DashboardInitialState: DashBoardCounts = {
  concencount: 0,
  branchcount: 0,
  schemecount: 0,
  groupcount: 0,
  customercount: 0,
  employecont: 0,
  giftcount: 0,
  expiredgroupcont: 0,
};

export const ChitGiftSchemeDetailInitialState = {
  cardnumber: 0,
  groupnumber: 0,
  cusname: "",
  groupname: "",
  installment: 0,
};
