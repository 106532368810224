import React, { useEffect, useState } from "react";
import CustomTable from "../../components/Table/table.tsx";
import { TableHeaders } from "../../components/Table/tableheader.js";
import { EmployeDetails } from "../../lib/dbobjectmodel.ts";
import { useFetch } from "../../lib/useFetch.tsx";
import Customspinner from "../../components/Customspinner.tsx";
function ViewEmplyee() {
  const [employees, setEmployees] = useState<EmployeDetails[]>();

  const { status, statusText, data, error, loading } = useFetch<
    EmployeDetails[]
  >(process.env.REACT_APP_GET_URL_EMPLOYE, [], undefined);

  useEffect(() => {
    setEmployees(data);
  }, [status == 200]);

  return (
    <>
      {!loading ? (
        <div className="content">
          <CustomTable
            datas={employees}
            headers={TableHeaders.employeheader}
            isedit={true}
            isdelete={false}
            iswhatapp={false}
            issms={false}
            iscustomer={false}
            isdefault={false}
            isapprove={false}
            isfilter={true}
            navigateurl="/admin/Employee"
            deleteurl=""
          />
        </div>
      ) : loading ? (
        <Customspinner text="Loading.." />
      ) : (
        ""
      )}
    </>
  );
}

export default ViewEmplyee;
