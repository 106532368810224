import React from "react";
import Dashboard from "./views/dashboard.tsx";
import GroupDetails from "./views/groupdetails.tsx";
import CustomerDetails from "./views/customerdetail.tsx";
import ChitGift from "./views/chitgift.tsx";
import BranchDetails from "./views/branchdetails.tsx";
import ViewBranchs from "./views/tablelists/viewbranchs.tsx";
import ViewGroups from "./views/tablelists/viewgroups.tsx";
import ViewCustomer from "./views/tablelists/viewcustomer.tsx";
import GroupBranchMapping from "./views/groupbranchmapping";
import Schemes from "./views/schemes.tsx";
import InstallmentDetails from "./views/installment.tsx";
import Concern from "./views/concern.tsx";
import ChitCard from "./views/chitcardcreation.tsx";
import ConcernUserRoles from "./views/concernuserroles.tsx";
import PaymentMode from "./views/paymentmode.tsx";
import ViewConcern from "./views/tablelists/viewconcern.tsx";
import ViewSchemes from "./views/tablelists/viewschemes.tsx";
import ViewChitGift from "./views/tablelists/viewchitgift.tsx";
import ViewEmplyee from "./views/tablelists/viewemploye.tsx";
import EmployeDetails from "./views/employeedetails.tsx";
import ViewUserRoles from "./views/tablelists/viewuserroles.tsx";

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "dashboard",
    component: Dashboard,
    layout: "/admin",
    index: 0,
  },
  {
    path: "/addconcern",
    name: "Add Concern",
    icon: "building",
    component: Concern,
    layout: "/admin",
    index: 11,
  },
  {
    path: "/concernroles",
    name: "Concern User Roles",
    icon: "gift",
    component: ConcernUserRoles,
    layout: "/admin",
    index: 13,
  },
  {
    path: "/schemes",
    name: "Schemes",
    icon: "gem",
    component: Schemes,
    layout: "/admin",
    index: 9,
  },

  {
    path: "/customerdetails",
    name: "Add Customer",
    icon: "user plus",
    component: CustomerDetails,
    layout: "/admin",
    index: 3,
  },
  {
    path: "/chitgift",
    name: "Chit Gift",
    icon: "gift",
    component: ChitGift,
    layout: "/admin",
    index: 4,
  },
  {
    path: "/groups",
    name: "Group",
    icon: "group",
    component: GroupDetails,
    layout: "/admin",
    index: 2,
  },
  {
    path: "/branch",
    name: "Branchs",
    icon: "code branch",
    component: BranchDetails,
    layout: "/admin",
    index: 1,
  },
  {
    path: "/Employee",
    name: "Employee",
    icon: "user circle outline",
    component: EmployeDetails,
    layout: "/admin",
    index: 8,
  },
  // {
  //   path: "/mapping",
  //   name: "Group Branch Mapping",
  //   icon: "gift",
  //   component: GroupBranchMapping,
  //   layout: "/admin",
  //   index: 8,
  // },
  // {
  //   path: "/installment",
  //   name: "Installment Details",
  //   icon: "money bill alternate outline",
  //   component: InstallmentDetails,
  //   layout: "/admin",
  //   index: 10,
  // },
  // {
  //   path: "/chitcard",
  //   name: "Chit Card Creation",
  //   icon: "add circle",
  //   component: ChitCard,
  //   layout: "/admin",
  //   index: 12,
  // },
  {
    path: "/paymentmode",
    name: "Payment Modes",
    icon: "payment",
    component: PaymentMode,
    layout: "/admin",
    index: 14,
  },
];

const viewroutes = [
  {
    path: "/viewbranchs",
    name: "Branchs",
    icon: "list alternate",
    component: ViewBranchs,
    layout: "/admin",
    index: 5,
  },
  {
    path: "/ViewEmployee",
    name: "Employee",
    icon: "list alternate",
    component: ViewEmplyee,
    layout: "/admin",
    index: 8,
  },
  {
    path: "/viewuserroles",
    name: "UserRoles",
    icon: "list alternate",
    component: ViewUserRoles,
    layout: "/admin",
    index: 5,
  },
  {
    path: "/viewconcerns",
    name: "Concerns",
    icon: "list alternate",
    component: ViewConcern,
    layout: "/admin",
    index: 8,
  },
  {
    path: "/viewschemes",
    name: "Schemes",
    icon: "list alternate",
    component: ViewSchemes,
    layout: "/admin",
    index: 9,
  },
  {
    path: "/viewgroups",
    name: "Groups",
    icon: "list alternate",
    component: ViewGroups,
    layout: "/admin",
    index: 6,
  },
  {
    path: "/viewcustomers",
    name: "Customers",
    icon: "list alternate",
    component: ViewCustomer,
    layout: "/admin",
    index: 7,
  },

  {
    path: "/ViewChitGift",
    name: "ChitGift",
    icon: "list alternate",
    component: ViewChitGift,
    layout: "/admin",
    index: 8,
  },
];

export { routes, viewroutes };
