import React, { useContext, useEffect, useState } from "react";
import {
  FormInput,
  FormGroup,
  Form,
  Button,
  FormField,
  Container,
  Checkbox,
  TextArea,
  Icon,
  FormSelect,
  Message,
  Label,
} from "semantic-ui-react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Dropdowns,
  EmployeDetails,
  GroupDetail,
  type CustomerDetail,
} from "../lib/dbobjectmodel";
import {
  Gender,
  getDropdown,
  Relationships,
  Religion,
} from "../utils/common.tsx";
import {
  CustomerInitialState,
  EmployeInitialState,
  GroupinitialState,
} from "../lib/initialState.ts";
import { maxLengthCheck } from "../utils/ProjectToast.js";
import { ToastContainer } from "react-toastify";
import { useGlobalContext } from "../lib/types.ts";
import { Utility } from "../utils/Utility.js";

function CustomerDetails(props: any) {
  let { state } = useLocation();
  const [relationship] = useState(Relationships);
  const [CustomerDetails, setCustomerDetails] =
    useState<CustomerDetail>(CustomerInitialState);
  const { sharedData, employeeInfo } = useContext(useGlobalContext);
  const [schemes, setSchemes] = useState<Dropdowns[]>([]);
  const [branches, setBranches] = useState<Dropdowns[]>([]);
  const [groupDetails, setGroupDetails] =
    useState<GroupDetail>(GroupinitialState);
  const [employee, setEmployee] = useState<EmployeDetails>(EmployeInitialState);

  const handleChange = (e: any, actionMeta: any) => {
    const { value, name } = e.target;
    let regex = /^[A-Za-z\s]+$/;

    if (e.target.type === "text") {
      let _value: string;
      switch (name) {
        case "street":
        case "taluk":
        case "city":
        case "state":
        case "customername":
        case "nomineename":
        case "area":
          if (value != "" && value.match(regex)) _value = value;
          else {
            setCustomerDetails({
              ...CustomerDetails,
              [e.target.name]: value.slice(0, -1),
            });
            return;
          }
          console.log(value.match(regex));

          break;
        default:
          _value = value;
          break;
      }

      setCustomerDetails({
        ...CustomerDetails,
        [e.target.name]: e.target.value,
      });
    } else {
      setCustomerDetails({
        ...CustomerDetails,
        [actionMeta.name]: actionMeta.value,
      });
    }

    if (actionMeta.name === "schemename") {
      const _scheme = schemes?.filter((s) => {
        return s.key === actionMeta.value;
      })[0];

      const amount = parseInt(_scheme.text.split("-")[1]);
      let _formattedamount: string = "";

      if (amount < 100000) {
        _formattedamount = `${amount / 1000}K`;
      } else if (amount >= 100000) {
        _formattedamount = `${amount / 100000}L`;
      }

      const randomNum = Math.floor(10000000 + Math.random() * 90000000);
      const _grpName =
        _scheme.text.split("-")[2] +
        "-" +
        _formattedamount +
        "-" +
        Utility.getAllMonth()[new Date().getMonth()].value.toLocaleUpperCase() +
        "-" +
        Utility.getCurrentYear();
      setTimeout(() => {
        setCustomerDetails({
          ...CustomerDetails,
          ["schemename"]: actionMeta.value,
          ["groupserialnumber"]: randomNum,
          ["schemeamount"]: amount,
          ["groupname"]: _grpName,
          ["schemeid"]: actionMeta.value,
        });

        setGroupDetails({
          ...groupDetails,
          ["serialnumber"]: randomNum,
          ["groupname"]: _grpName,
          ["installmentamount"]: parseInt(_scheme.text.split("-")[1]),
        });
      }, 200);
    }
  };

  useEffect(() => {
    props.onTrigger(process.env.REACT_APP_GET_POST_CUSTOMER);
    setSchemes(getDropdown("scheme", sharedData));
    setBranches(getDropdown("branch", sharedData));

    setTimeout(() => {
      setCustomerDetails({
        ...CustomerDetails,
        ["employeeid"]: employeeInfo.ecno,
      });
    }, 500);
  }, []);

  useEffect(() => {
    if (state) {
      let _data: CustomerDetail = JSON.parse(state?.data);
      setTimeout(() => {
        setCustomerDetails(_data);
        props.onTrigger(process.env.REACT_APP_GET_PUT_CUSTOMER);
      }, 1000);
    }
  }, [state?.data]);

  useEffect(() => {
    props.onProcessdata(CustomerDetails);
  });

  useEffect(() => {
    props.onReset(false);
    props.onTrigger(process.env.REACT_APP_GET_POST_CUSTOMER);
    window.history.replaceState({}, "");
    setCustomerDetails(CustomerInitialState);
    state = null;
    setTimeout(() => {
      setCustomerDetails({
        ...CustomerDetails,
        ["employeeid"]: employeeInfo.ecno,
      });
    }, 500);
  }, [props.reset]);

  return (
    <div className="content">
      <Container className="project-form">
        <Form>
          <Label as="a" color="red" ribbon>
            Scheme
          </Label>
          <FormGroup widths="equal">
            <FormSelect
              type="select"
              fluid={true}
              label="Scheme"
              value={CustomerDetails?.schemeid}
              placeholder="Type or select scheme name"
              id="schemename"
              name="schemename"
              error={CustomerDetails?.schemeid == "" ? props.isValid : false}
              onChange={handleChange}
              maxLength="150"
              options={schemes}
              search
              scrolling
              disabled={CustomerDetails?.insert_update > 1 ? true : false}
            />

            <FormInput
              type="number"
              fluid={true}
              label="Scheme Amount"
              value={CustomerDetails?.schemeamount}
              placeholder="Scheme Amount"
              id="schemeamount"
              name="schemeamount"
              maxLength="50"
              style={{ opacity: "0.6" }}
            />
          </FormGroup>
          <FormGroup widths="equal">
            <FormInput
              type="number"
              fluid={true}
              label="Group Serial Number"
              value={CustomerDetails?.groupserialnumber}
              placeholder="Serial Number"
              id="groupserialnumber"
              name="groupserialnumber"
              maxLength="150"
              style={{ opacity: "0.6" }}
            />

            <FormInput
              type="text"
              fluid={true}
              label="Group Name"
              value={CustomerDetails?.groupname}
              placeholder="Group Name"
              id="groupname"
              name="groupname"
              maxLength="50"
              style={{ opacity: "0.6" }}
            />
          </FormGroup>
          <Label as="a" color="red" ribbon>
            Customer Info
          </Label>
          <FormGroup widths="equal">
            <FormInput
              type="text"
              fluid={true}
              label="Customer Name"
              value={CustomerDetails?.customername}
              placeholder="Customer Name"
              id="customername"
              name="customername"
              error={
                CustomerDetails?.customername == "" ? props.isValid : false
              }
              onChange={handleChange}
              maxLength="150"
            />

            <FormInput
              type="number"
              fluid={true}
              label="Card Number"
              value={CustomerDetails?.cardnumber}
              placeholder="Card Number"
              id="cardnumber"
              name="cardnumber"
              error={CustomerDetails?.cardnumber == "" ? props.isValid : false}
              onChange={handleChange}
              maxLength="50"
              disabled={CustomerDetails?.insert_update > 1 ? true : false}
            />
          </FormGroup>

          <FormGroup widths="equal">
            <FormField>
              <FormInput
                type="number"
                fluid={true}
                label="Aadhar Number"
                value={CustomerDetails?.aadharnumber}
                placeholder="Aadhar Number"
                id="aadharnumber"
                name="aadharnumber"
                error={
                  CustomerDetails?.aadharnumber == "" ? props.isValid : false
                }
                onChange={handleChange}
                onInput={maxLengthCheck}
                maxLength="12"
              />

              {CustomerDetails?.aadharnumber != "" &&
              props.isFormatted.includes("AADHAR") ? (
                <Label basic color="red" pointing="above">
                  Please enter valid AADHAR number
                </Label>
              ) : (
                ""
              )}
            </FormField>
            <FormSelect
              type="select"
              fluid={true}
              label="Branch"
              value={CustomerDetails?.branch}
              placeholder="Select branch"
              id="branch"
              name="branch"
              error={CustomerDetails?.branch == "" ? props.isValid : false}
              onChange={handleChange}
              options={branches}
            />
          </FormGroup>

          <FormGroup roup widths="equal">
            <FormInput
              fluid={true}
              type="number"
              value={CustomerDetails?.doornumber}
              label="Door Number"
              error={CustomerDetails?.doornumber == "" ? props.isValid : false}
              placeholder="Door Number"
              id="doornumber"
              name="doornumber"
              onChange={handleChange}
              onInput={maxLengthCheck}
              maxLength="20"
            />
            <FormInput
              fluid={true}
              type="text"
              value={CustomerDetails?.street}
              label="Street"
              error={CustomerDetails?.street == "" ? props.isValid : false}
              placeholder="Street"
              id="street"
              name="street"
              onChange={handleChange}
              maxLength="150"
            />
          </FormGroup>
          <FormGroup widths="equal">
            <FormInput
              fluid={true}
              type="number"
              value={CustomerDetails?.pincode}
              label="Pincode"
              error={CustomerDetails?.pincode == "" ? props.isValid : false}
              placeholder="Pincode"
              id="pincode"
              name="pincode"
              onChange={handleChange}
              onInput={maxLengthCheck}
              maxLength="6"
            />
            <FormInput
              fluid={true}
              type="number"
              value={CustomerDetails?.customermobilenumber}
              label="Mobile Number"
              error={
                CustomerDetails?.customermobilenumber == ""
                  ? props.isValid
                  : false
              }
              placeholder="Mobile Number"
              id="customermobilenumber"
              name="customermobilenumber"
              onChange={handleChange}
              onInput={maxLengthCheck}
              maxLength="10"
            />
          </FormGroup>
          <FormGroup widths="equal">
            <FormInput
              type="text"
              fluid={true}
              label="Area"
              value={CustomerDetails?.area}
              placeholder="Area"
              id="area"
              name="area"
              error={CustomerDetails?.area == "" ? props.isValid : false}
              onChange={handleChange}
              maxLength="150"
            />

            <FormInput
              type="text"
              fluid={true}
              label="Taluk"
              value={CustomerDetails?.taluk}
              placeholder="Taluk"
              id="taluk"
              name="taluk"
              error={CustomerDetails?.taluk == "" ? props.isValid : false}
              onChange={handleChange}
              maxLength="150"
            />
          </FormGroup>
          <FormGroup widths={"equal"}>
            <FormInput
              type="text"
              fluid={true}
              label="City"
              value={CustomerDetails?.city}
              placeholder="City"
              id="city"
              name="city"
              error={CustomerDetails?.city == "" ? props.isValid : false}
              onChange={handleChange}
              maxLength="150"
            />

            <FormInput
              type="text"
              fluid={true}
              label="State"
              value={CustomerDetails?.state}
              placeholder="State"
              id="state"
              name="state"
              error={CustomerDetails?.state == "" ? props.isValid : false}
              onChange={handleChange}
              maxLength="150"
            />
          </FormGroup>
          <FormGroup widths={"equal"}>
            <FormField>
              <FormInput
                type="email"
                fluid={true}
                label="Email ID"
                value={CustomerDetails?.customeremail}
                placeholder="Email ID"
                id="customeremail"
                name="customeremail"
                error={
                  CustomerDetails?.customeremail == "" ? props.isValid : false
                }
                onChange={handleChange}
                maxLength="200"
              />
              {CustomerDetails?.customeremail != "" &&
              props.isFormatted.includes("email") ? (
                <Label basic color="red" pointing="above">
                  Please enter valid email
                </Label>
              ) : (
                ""
              )}
            </FormField>

            <FormInput
              type="text"
              fluid={true}
              label="Employee ID"
              value={CustomerDetails?.employeeid}
              placeholder="Employee ID"
              id="employeeid"
              name="employeeid"
              error={CustomerDetails?.employeeid == "" ? props.isValid : false}
              style={{ opacity: "0.6" }}
              readOnly={true}
            />
          </FormGroup>
          <Label as="a" color="red" ribbon>
            Nominee Info
          </Label>
          <FormGroup widths={"equal"}>
            <FormInput
              type="text"
              fluid={true}
              label="Name"
              value={CustomerDetails?.nomineename}
              placeholder="Name"
              id="nomineename"
              name="nomineename"
              error={CustomerDetails?.nomineename == "" ? props.isValid : false}
              onChange={handleChange}
              maxLength="150"
            />
            <FormSelect
              type="select"
              fluid={true}
              options={relationship}
              label="Relationship"
              value={CustomerDetails?.relationship}
              placeholder="Select Relationship"
              id="relationship"
              name="relationship"
              error={
                CustomerDetails?.relationship == "" ? props.isValid : false
              }
              onChange={handleChange}
              maxLength="100"
            />
          </FormGroup>
          <FormInput
            type="number"
            fluid={true}
            label="Mobile Number"
            value={CustomerDetails?.nomineemobilenumber}
            placeholder="Mobile Number"
            id="nomineemobilenumber"
            name="nomineemobilenumber"
            error={
              CustomerDetails?.nomineemobilenumber == "" ? props.isValid : false
            }
            onChange={handleChange}
            onInput={maxLengthCheck}
            maxLength="10"
          />

          <props.button {...props} />
        </Form>
        <ToastContainer />
      </Container>{" "}
    </div>
  );
}
export default CustomerDetails;
