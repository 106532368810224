import React, { useEffect, useState } from "react";

import { Card, CardBody, CardFooter, CardTitle, Row, Col } from "reactstrap";

import { FormField, Icon } from "semantic-ui-react";

import Skeleton from "react-loading-skeleton";
import { useLocation } from "react-router-dom";
import "react-loading-skeleton/dist/skeleton.css";
import { DashBoardCounts } from "../lib/dbobjectmodel";
import { DashboardInitialState } from "../lib/initialState.ts";
import { useFetch } from "../lib/useFetch.tsx";

function DashboardTiles() {
  const { state } = useLocation();
  const [skeleton, setSkeleton] = useState<boolean>(true);
  const [dashboardTileCount, setDashboardTileCount] = useState<DashBoardCounts>(
    DashboardInitialState
  );

  const { status, statusText, data, error, loading } = useFetch<
    DashBoardCounts[]
  >(process.env.REACT_APP_GET_URL_DASHBOARD, [], undefined);

  useEffect(() => {
    setTimeout(() => {
      setSkeleton(false);
      setDashboardTileCount(data[0] ? data[0] : DashboardInitialState);
    }, 2000);
  }, [status == 200]);

  return (
    <>
      <div className="content">
        <Row>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <Icon name="building" color="teal" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Concerns</p>
                      <CardTitle tag="p">
                        {skeleton ? (
                          <Skeleton count={1} />
                        ) : (
                          dashboardTileCount?.concencount
                        )}
                      </CardTitle>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats" style={{ fontWeight: 600 }}>
                  <Icon name="building" /> Total Concern
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <Icon name="sitemap" color="teal" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Branches</p>
                      <CardTitle tag="p">
                        {skeleton ? (
                          <Skeleton count={1} />
                        ) : (
                          dashboardTileCount?.branchcount
                        )}
                      </CardTitle>

                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats" style={{ fontWeight: 600 }}>
                  <Icon name="sitemap" /> Total Branches
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <Icon name="gem" color="teal" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Schemes</p>
                      <CardTitle tag="p">
                        {skeleton ? (
                          <Skeleton count={1} />
                        ) : (
                          dashboardTileCount?.schemecount
                        )}
                      </CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats" style={{ fontWeight: 600 }}>
                  <Icon name="gem" /> Total Schemes
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-primary">
                      <Icon name="object group outline" color="teal" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Groups</p>
                      <CardTitle tag="p">
                        {skeleton ? (
                          <Skeleton count={1} />
                        ) : (
                          dashboardTileCount?.groupcount
                        )}
                      </CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats" style={{ fontWeight: 600 }}>
                  <Icon name="object group outline" />
                  Total Groups
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <Icon name="users" color="teal" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Customers</p>
                      <CardTitle tag="p">
                        {skeleton ? (
                          <Skeleton count={1} />
                        ) : (
                          dashboardTileCount?.customercount
                        )}
                      </CardTitle>

                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats" style={{ fontWeight: 600 }}>
                  <Icon name="users" /> Total Customers
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-primary">
                      <Icon name="user outline" color="teal" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Employees</p>
                      <CardTitle tag="p">
                        {skeleton ? (
                          <Skeleton count={1} />
                        ) : (
                          dashboardTileCount?.employecont
                        )}
                      </CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats" style={{ fontWeight: 600 }}>
                  <Icon name="user outline" />
                  Total Employees
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <Icon name="gift" color="teal" className="fa-bounce" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Gifts</p>
                      <CardTitle tag="p">
                        {skeleton ? (
                          <Skeleton count={1} />
                        ) : (
                          dashboardTileCount?.giftcount
                        )}
                      </CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats" style={{ fontWeight: 600 }}>
                  <Icon name="gift" /> Total Gifts
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <Icon name="ban" color="teal" className="fa-bounce" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Expired Groups</p>
                      <CardTitle tag="p">
                        {skeleton ? (
                          <Skeleton count={1} />
                        ) : (
                          dashboardTileCount?.expiredgroupcont
                        )}
                      </CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats" style={{ fontWeight: 600 }}>
                  <Icon name="ban" color="teal" className="fa-bounce" /> Total
                  Expired Groups
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default DashboardTiles;
