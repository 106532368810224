import React, { useEffect, useState } from "react";
import {
  FormInput,
  FormGroup,
  Form,
  Button,
  FormField,
  Container,
  Checkbox,
  TextArea,
  Icon,
  FormSelect,
  Dimmer,
  Image,
  Loader,
} from "semantic-ui-react";
import { useLocation, useNavigate } from "react-router-dom";
import { type SchemeDetail } from "../lib/dbobjectmodel";
import { SchemeInitalState } from "../lib/initialState.ts";
import { ErrorToast, maxLengthCheck } from "../utils/ProjectToast.js";
import { ToastContainer } from "react-toastify";

function Schemes(props: any) {
  let { state } = useLocation();
  const navigate = useNavigate();
  const [schemeDetails, setSchemeDetails] =
    useState<SchemeDetail>(SchemeInitalState);

  const handleChange = (e: any, actionMeta: any) => {
    const { value, name } = e.target;
    const regex = /^[A-Za-z\s]+$/;

    if (e.target.type === "text") {
      let _value: string;
      switch (name) {
        case "schemename":
        case "code":
          if (value != "" && value.match(regex)) _value = value;
          else {
            setSchemeDetails({
              ...schemeDetails,
              [e.target.name]: value.slice(0, -1),
            });
            return;
          }
          break;
        default:
          _value = value;
          break;
      }
      setSchemeDetails({ ...schemeDetails, [e.target.name]: _value });
    } else if (e.target.type === "number" && e.target.value >= 0) {
      setSchemeDetails({ ...schemeDetails, [e.target.name]: e.target.value });
    } else if (actionMeta.type === "radio") {
      setSchemeDetails({
        ...schemeDetails,
        [actionMeta.name]: actionMeta.value,
      });
    }
  };

  useEffect(() => {
    props.onTrigger(process.env.REACT_APP_GET_SCHEMES);
  }, []);

  useEffect(() => {
    // ErrorToast("Please fill all mandatory fields");
  }, [props.isValid]);

  useEffect(() => {
    if (state) {
      let _data: SchemeDetail = JSON.parse(state?.data);
      _data.entrydate = new Date(_data.entrydate.split("-").reverse().join("-"))
        .toISOString()
        .split("T")[0];
      _data.insert_update = 2;
      setTimeout(() => {
        setSchemeDetails(_data);
        props.onTrigger(process.env.REACT_APP_PUT_SCHEME);
      }, 500);
    }
  }, [state?.data]);

  useEffect(() => {
    props.onProcessdata(schemeDetails);
  });

  useEffect(() => {
    setSchemeDetails(SchemeInitalState);
    props.onReset(false);
    props.onTrigger(process.env.REACT_APP_POST_SCHEME);
    window.history.replaceState({}, "");
  }, [props.reset]);

  return (
    <div className="content">
      <Container className="project-form">
        <Form>
          <FormGroup widths="equal">
            <FormInput
              type="number"
              value={schemeDetails?.entrynumber}
              id="entrynumber"
              name="entrynumber"
              error={schemeDetails?.entrynumber == "" ? props.isValid : false}
              onChange={handleChange}
              fluid={true}
              label="Entry Number"
              placeholder="Entry Number"
              onInput={maxLengthCheck}
              maxLength="25"
            />
            <FormInput
              type="date"
              id="entrydate"
              value={schemeDetails?.entrydate}
              name="entrydate"
              onChange={handleChange}
              fluid={true}
              label="Entry Date"
              placeholder="Entry Date"
            />
          </FormGroup>
          <FormGroup widths="equal">
            <FormInput
              type="text"
              value={schemeDetails?.schemename}
              id="schemename"
              name="schemename"
              error={schemeDetails?.schemename == "" ? props.isValid : false}
              onChange={handleChange}
              fluid={true}
              label="Scheme Name"
              placeholder="Scheme Name"
              maxLength="150"
            />
            <FormInput
              type="text"
              value={schemeDetails?.code}
              id="code"
              name="code"
              error={schemeDetails?.code == "" ? props.isValid : false}
              onChange={handleChange}
              fluid={true}
              label="Scheme Code"
              placeholder="Scheme Code"
              maxLength="50"
            />
          </FormGroup>

          <FormInput
            type="number"
            id="installmentamount"
            value={schemeDetails?.installmentamount}
            name="installmentamount"
            onChange={handleChange}
            fluid={true}
            label="Installment Amount"
            placeholder="Installment Amount"
            error={
              schemeDetails?.installmentamount == "" ? props.isValid : false
            }
            onInput={maxLengthCheck}
            maxLength="25"
          />
          <FormGroup widths="equal">
            <FormInput
              type="number"
              value={schemeDetails?.yellowgoldwastage}
              id="yellowgoldwastage"
              name="yellowgoldwastage"
              error={
                schemeDetails?.yellowgoldwastage == "" ? props.isValid : false
              }
              onChange={handleChange}
              fluid={true}
              label="Yellow Gold Wastage"
              placeholder="Yellow Gold Wastage"
              onInput={maxLengthCheck}
              maxLength="25"
            />
            <FormInput
              type="number"
              id="antiqgoldwastage"
              value={schemeDetails?.antiqgoldwastage}
              name="antiqgoldwastage"
              onChange={handleChange}
              fluid={true}
              label="Antiq Gold Wastage"
              placeholder="Antiq Gold Wastage"
              error={
                schemeDetails?.antiqgoldwastage == "" ? props.isValid : false
              }
              onInput={maxLengthCheck}
              maxLength="25"
            />
          </FormGroup>

          <FormGroup widths="equal">
            <FormInput
              type="number"
              value={schemeDetails?.diamondmc}
              id="diamondmc"
              name="diamondmc"
              error={schemeDetails?.diamondmc == "" ? props.isValid : false}
              onChange={handleChange}
              fluid={true}
              label="Diamond MC"
              placeholder="Diamond MC"
              onInput={maxLengthCheck}
              maxLength="25"
            />
            <FormInput
              type="number"
              id="diamondcent"
              value={schemeDetails?.diamondcent}
              name="diamondcent"
              onChange={handleChange}
              fluid={true}
              label="DiamondCent"
              placeholder="DiamondCent"
              error={schemeDetails?.diamondcent == "" ? props.isValid : false}
              onInput={maxLengthCheck}
              maxLength="25"
            />
          </FormGroup>

          <FormGroup widths="equal">
            <FormInput
              type="number"
              value={schemeDetails?.silvermc}
              id="silvermc"
              name="silvermc"
              error={schemeDetails?.silvermc == "" ? props.isValid : false}
              onChange={handleChange}
              fluid={true}
              label="Silver MC"
              placeholder="Silver MC"
              onInput={maxLengthCheck}
              maxLength="25"
            />
            <FormInput
              type="number"
              id="giftarticlemrp"
              value={schemeDetails?.giftarticlemrp}
              name="giftarticlemrp"
              onChange={handleChange}
              fluid={true}
              label="Gift Article MRP "
              placeholder="Gift Article MRP"
              error={
                schemeDetails?.giftarticlemrp == "" ? props.isValid : false
              }
              onInput={maxLengthCheck}
              maxLength="25"
            />
          </FormGroup>

          <div className="field">
            <span className="d-f-c "> Collection Type</span>
            <div className="radio-input_cs">
              <label>
                <input
                  value="Daily"
                  checked={!schemeDetails.collectiontype}
                  onChange={(e) => {
                    setSchemeDetails({
                      ...schemeDetails,
                      ["collectiontype"]: false,
                    });
                  }}
                  name="dailycollection"
                  id="dailycollectionyes"
                  type="radio"
                />
                <span>Daily</span>
              </label>
              <label>
                <input
                  value="Monthly"
                  checked={schemeDetails.collectiontype}
                  onChange={(e) => {
                    setSchemeDetails({
                      ...schemeDetails,
                      ["collectiontype"]: true,
                    });
                  }}
                  name="dailycollection"
                  id="dailycollectionyes"
                  type="radio"
                />
                <span>Monthly</span>
              </label>
              <span className="selection_cs"></span>
            </div>
          </div>

          <FormGroup widths="equal">
            <div className="field">
              <span className="d-f-c ">Gift</span>
              <div className="radio-input_cs">
                <label>
                  <input
                    value="No"
                    checked={!schemeDetails.gift}
                    onChange={(e) => {
                      setSchemeDetails({ ...schemeDetails, ["gift"]: false });
                    }}
                    name="gift"
                    id="giftno"
                    type="radio"
                  />
                  <span>No</span>
                </label>
                <label>
                  <input
                    value="Yes"
                    checked={schemeDetails.gift}
                    onChange={(e) => {
                      setSchemeDetails({ ...schemeDetails, ["gift"]: true });
                    }}
                    name="gift"
                    id="giftyes"
                    type="radio"
                  />
                  <span>Yes</span>
                </label>
                <span className="selection_cs"></span>
              </div>
            </div>
            <div className="field">
              <span className="d-f-c ">Lucky Draw</span>
              <div className="radio-input_cs">
                <label>
                  <input
                    value="No"
                    checked={!schemeDetails.luckydraw}
                    onChange={(e) => {
                      setSchemeDetails({
                        ...schemeDetails,
                        ["luckydraw"]: false,
                      });
                    }}
                    name="luckydraw"
                    id="luckydrawno"
                    type="radio"
                  />
                  <span>No</span>
                </label>
                <label>
                  <input
                    value="Yes"
                    checked={schemeDetails.luckydraw}
                    onChange={(e) => {
                      setSchemeDetails({
                        ...schemeDetails,
                        ["luckydraw"]: true,
                      });
                    }}
                    name="luckydraw"
                    id="luckydrawyes"
                    type="radio"
                  />
                  <span>Yes</span>
                </label>
                <span className="selection_cs"></span>
              </div>
            </div>
          </FormGroup>

          <FormGroup widths="equal">
            <div className="field">
              <span className="d-f-c ">Weight</span>
              <div className="radio-input_cs">
                <label>
                  <input
                    value="No"
                    checked={!schemeDetails.weight}
                    onChange={(e) => {
                      setSchemeDetails({ ...schemeDetails, ["weight"]: false });
                    }}
                    name="weight"
                    id="weightno"
                    type="radio"
                  />
                  <span>No</span>
                </label>
                <label>
                  <input
                    value="Yes"
                    checked={schemeDetails.weight}
                    onChange={(e) => {
                      setSchemeDetails({ ...schemeDetails, ["weight"]: true });
                    }}
                    name="weight"
                    id="weightyes"
                    type="radio"
                  />
                  <span>Yes</span>
                </label>
                <span className="selection_cs"></span>
              </div>
            </div>
            <div className="field">
              <span className="d-f-c ">Status</span>
              <div className="radio-input_cs">
                <label>
                  <input
                    value="No"
                    checked={!schemeDetails.status}
                    onChange={(e) => {
                      setSchemeDetails({
                        ...schemeDetails,
                        ["status"]: false,
                      });
                    }}
                    name="status"
                    id="statusno"
                    type="radio"
                  />
                  <span>No</span>
                </label>
                <label>
                  <input
                    value="Yes"
                    checked={schemeDetails.status}
                    onChange={(e) => {
                      setSchemeDetails({
                        ...schemeDetails,
                        ["status"]: true,
                      });
                    }}
                    name="status"
                    id="statusyes"
                    type="radio"
                  />
                  <span>Yes</span>
                </label>
                <span className="selection_cs"></span>
              </div>
            </div>
          </FormGroup>
          <props.button {...props} />
        </Form>
        <ToastContainer />
      </Container>
    </div>
  );
}
export default Schemes;
