import React, { useEffect, useState } from "react";
import CustomTable from "../../components/Table/table.tsx";
import { TableHeaders } from "../../components/Table/tableheader";
import Customspinner from "../../components/Customspinner.tsx";
import { useFetch } from "../../lib/useFetch.tsx";
import { ConcernRoleDetail } from "../../lib/dbobjectmodel";
import { ConcernUserInitialState } from "../../lib/initialState.ts";

function ViewUserRoles() {
  const [userroles, setUserRoles] = useState<ConcernRoleDetail[]>();

  const { status, statusText, data, error, loading } = useFetch<
    ConcernRoleDetail[]
  >(process.env.REACT_APP_GET_URL_CONCERNUSERROLE, []);

  useEffect(() => {
    setUserRoles(data);
  }, [status == 200]);

  return (
    <>
      {!loading ? (
        <div className="content">
          <CustomTable
            datas={userroles || []}
            headers={TableHeaders.userRoleheader}
            isedit={true}
            isdelete={false}
            iswhatapp={false}
            issms={false}
            iscustomer={false}
            isdefault={false}
            isapprove={false}
            isfilter={true}
            navigateurl="/admin/concernroles"
          />
        </div>
      ) : loading ? (
        <Customspinner text="Loading.." />
      ) : (
        ""
      )}
    </>
  );
}

export default ViewUserRoles;
