import { useState, useEffect } from "react";
import {
  type HttpMethods,
  type ApiTriggerResponse,
  type TApiResponse,
  Result,
} from "./types";
import { EmployeDetails } from "./dbobjectmodel";

export const useFetch = <T,>(
  url: any,
  initalState: T,
  inputs?: any
): TApiResponse<T> => {
  const [status, setStatus] = useState<Number>(0);
  const [statusText, setStatusText] = useState<String>("");
  const [data, setData] = useState<T>(initalState);
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const getAPIData = async () => {
    setLoading(true);
    const queryString = new URLSearchParams(inputs).toString();

    try {
      const getUrl = queryString ? `${url}?${queryString}` : url;
      const apiResponse = await fetch(getUrl);
      const json = await apiResponse.json();
      setStatus(apiResponse.status);
      setStatusText(apiResponse.statusText);
      setData(json?.data?.[0]);
    } catch (error) {
      setError(error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  };

  useEffect(() => {
    getAPIData();
  }, [url]);

  return { status, statusText, data, error, loading };
};

export const HttpTrigger = async <T,>(
  url: string,
  method: HttpMethods,
  data: T
): Promise<Result> => {
  let httploading: boolean = false;
  let httpstatus: Number = 0;
  let httpmessage: any = null;

  const requestOptions = {
    method: method,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  // const http = async () => {
  httploading = true;
  try {
    const apiResponse: any = await fetch(url, requestOptions);
    const json = await apiResponse.json();
    httpmessage = json.message;
    console.log(httpmessage);
    httpstatus = json.status;
  } catch (error) {
    httpmessage = error;
  } finally {
    setTimeout(() => {
      httploading = false;
    }, 500);
  }

  return new Promise((resolve, reject) => {
    resolve(httpmessage);
  });
};

export const HttpGet = async (url: any, data: any) => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
    // body: JSON.stringify({ data: data }),
  };
  const getUrl = `${url}?data=${data}`;
  const apiResponse = await fetch(getUrl, requestOptions);
  const json = await apiResponse.json();
  return json?.data?.[0];
};

export const HttpLoginTrigger = async <T,>(
  url: string,
  method: HttpMethods,
  data: T
): Promise<any> => {
  let result: any;

  const requestOptions = {
    method: method,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  const apiResponse: any = await fetch(url, requestOptions);
  result = await apiResponse.json();

  return new Promise((resolve, reject) => {
    resolve(result);
  });
};
