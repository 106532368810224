import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import DashboardTiles from "../components/dashboardtiles.tsx";

function Dashboard() {
  return (
    <div className="content">
      <Row>
        <DashboardTiles />
      </Row>
    </div>
  );
}

export default Dashboard;
