export const TableHeaders = {
  viewBranchs: [
    {
      value: "companyname",
      text: "Company Name",
      ishide: false,
      islink: false,
    },
    { value: "legalname", text: "Legal Name", ishide: false, islink: false },
    { value: "branchname", text: "Branch Name", ishide: false, islink: false },
    { value: "branchcode", text: "Branch Code ", ishide: false, islink: false },
    { value: "isdeleted", text: "Active", ishide: false, islink: false },
  ],
  viewGroups: [
    { value: "groupname", text: "Group Name", ishide: false, islink: false },
    { value: "startdate", text: "Start Date", ishide: false, islink: false },
    { value: "enddate", text: "End Date", ishide: false, islink: false },
    {
      value: "installmentamount",
      text: "Inst. Amount",
      ishide: false,
      islink: false,
    },
    { value: "serialnumber", text: "Serial #", ishide: true, islink: false },
  ],
  viewCustomers: [
    {
      value: "customername",
      text: "Customer Name",
      ishide: false,
      islink: false,
    },
    {
      value: "customermobilenumber",
      text: "Mobile",
      ishide: false,
      islink: false,
    },
    { value: "area", text: "Area", ishide: false, islink: false },
    { value: "isdeleted", text: "Active", ishide: false, islink: false },
  ],
  concernHeader: [
    { value: "companycode", text: "Code", ishide: false, islink: false },
    {
      value: "companyname",
      text: "Name",
      ishide: false,
      islink: false,
    },
    {
      value: "companytype",
      text: "Type",
      ishide: false,
      islink: false,
    },
    {
      value: "ownerpartnername",
      text: "Owner/Partner Name",
      ishide: false,
      islink: false,
    },
    {
      value: "yearcode",
      text: "Year-Code",
      ishide: false,
      islink: false,
    },
    { value: "isdeleted", text: "Active", ishide: false, islink: false },
  ],

  schemeheader: [
    {
      value: "entrynumber",
      text: "Entry Number",
      ishide: false,
      islink: false,
    },
    { value: "entrydate", text: "Enrty Date", ishide: false, islink: false },
    { value: "schemename", text: "Scheme Name", ishide: false, islink: false },
    { value: "code", text: "Code", ishide: false, islink: false },
    {
      value: "installmentamount",
      text: "Amount",
      ishide: false,
      islink: false,
    },
    { value: "isdeleted", text: "Active", ishide: false, islink: false },
    { value: "schemeid", text: "Scheme Id", ishide: true, islink: false },
  ],
  chitgiftheader: [
    {
      value: "cardnumber",
      text: "Chit Card Number",
      ishide: false,
      islink: false,
    },
    {
      value: "cusname",
      text: "Customer Name",
      ishide: false,
      islink: false,
    },
    {
      value: "schemename",
      text: "Scheme Name",
      ishide: false,
      islink: false,
    },
    { value: "groupname", text: "Group Name", ishide: false, islink: false },
    {
      value: "groupnumber",
      text: "Group Number",
      ishide: false,
      islink: false,
    },
    { value: "gifttype", text: "Gift Type", ishide: false, islink: false },
    { value: "giftvalue", text: "Gift Value", ishide: false, islink: false },
    { value: "isdeleted", text: "Active", ishide: false, islink: false },
  ],
  employeheader: [
    { value: "ecno", text: "EC Number", ishide: false, islink: false },
    {
      value: "employename",
      text: "Employe Name",
      ishide: false,
      islink: false,
    },
    { value: "department", text: "Department ", ishide: false, islink: false },
    { value: "division", text: "Division ", ishide: false, islink: false },
    {
      value: "designation",
      text: "Designation ",
      ishide: false,
      islink: false,
    },
    {
      value: "companyname",
      text: "Company Name",
      ishide: false,
      islink: false,
    },
    { value: "branchname", text: "Branch Name", ishide: false, islink: false },
    { value: "isdeleted", text: "Active", ishide: false, islink: false },
  ],

  userRoleheader: [
    {
      value: "companyname",
      text: "Concern Name",
      ishide: false,
      islink: false,
    },
    { value: "userrole", text: "Role", ishide: false, islink: false },
    { value: "isdeleted", text: "Active", ishide: false, islink: false },
  ],
};
