import React, { useState, useEffect } from "react";
import {
  FormInput,
  FormGroup,
  Form,
  Button,
  FormField,
  Container,
  Checkbox,
  TextArea,
  Icon,
  FormSelect,
  Popup,
  AccordionTitle,
  AccordionContent,
  Accordion,
} from "semantic-ui-react";
import { PaymentDetail } from "../lib/dbobjectmodel";
import { PaymentInitialState } from "../lib/initialState.ts";
import { useLocation, useNavigate } from "react-router-dom";
function PaymentMode(props: any) {
  let { state } = useLocation();
  const [PaymentDetails, setPaymentDetails] = useState<PaymentDetail>(PaymentInitialState);
  const [activeIndex, setActiveIndex] = useState(1);

  const handleClick = (e:any, titleProps : any) => {
    const { index } = titleProps
    const newIndex = activeIndex === index ? -1 : index
    setActiveIndex(newIndex);
  }
  const handlechange = (e: any, actionMeta: any) => {
    if (e.target.type === "text") {
      setPaymentDetails({ ...PaymentDetails, [e.target.name]: e.target.value });
    } else {
      setPaymentDetails({ ...PaymentDetails, [actionMeta.name]: actionMeta.value });
    }
  };
  console.log(PaymentDetails);
  
  useEffect(() => {
    props.onTrigger(process.env.REACT_APP_GET_URL_PAYMENT);
  }, []);


  useEffect(() => {
    if (state) {
      let _data: PaymentDetail = JSON.parse(state?.data);
     // _data.entrydate = new Date(_data.entrydate.split("-").reverse().join("-"))
      //  .toISOString()
      //  .split("T")[0];
      _data.insert_update = 2;
      setTimeout(() => {
        setPaymentDetails(_data);
        props.onTrigger(process.env.REACT_APP_GET_PUT_PAYMENT);
      }, 500);
    }
  }, [state?.data]);
  useEffect(() => {
    props.onProcessdata(PaymentDetails);
  });
  
  useEffect(() => {
    setPaymentDetails(  PaymentInitialState)
    props.onReset(false);
      props.onTrigger(process.env.REACT_APP_GET_POST_PAYMENT);
  }, [state?.data]);
  return (
    <div className="content">
      <Container className="project-form">
        <Form>
          <FormGroup widths="equal">
          <FormInput
            type="number"
            id="entryno"
            name="entryno"
            placeholder="***"
            label="Entry Number"
            fluid={true}
            value={PaymentDetails?.entryno }
          onChange={handlechange}
          />
            <FormInput
            id="cardnumber"
            name="cardnumber"
            placeholder="Enter Card number"
            label="Card Number"
            type="text"
            fluid={true}
              value={PaymentDetails?.cardnumber }
               onChange={handlechange}
            />  
          </FormGroup>
          
          <FormGroup widths="equal">
          <FormInput
            type="text"
            id="grpname"
            name="grpname"
            placeholder="0"
            label="Group Name"
            fluid={true}
           value={PaymentDetails?.grpname }
              onChange={handlechange}
           //   disabled={true}
          />
          <FormInput
            id="grpnumber"
            name="grpnumber"
            placeholder="Enter Group number"
            label="Group Number" 
            type="text"
            fluid={true}
            value={PaymentDetails?.grpnumber }
            onChange={handlechange}
            />  
          </FormGroup>
          
          <FormGroup widths="equal">
          <FormInput
            type="number"
            id="installmentamount"
            name="installmentamount"
            placeholder=" 0"
            label="Installment Amount"
            fluid={true}
            value={PaymentDetails?.installmentamount }
            onChange={handlechange}
          />
            <FormInput
            type="number"
            id="installmentweight"
            name="installmentweight"
            placeholder="0.00"
            label="Installment Weight" 
            fluid={true}
            value={PaymentDetails?.installmentweight }
            onChange={handlechange}
            />  
          </FormGroup>
          
          <FormGroup widths="equal">
          <FormInput
            type="number"
            id="actualinstallment"
            name="actualinstallment"
            placeholder="0"
            label=" Actual Installment" 
            fluid={true}
            value={PaymentDetails?.actualinstallment }
            onChange={handlechange}
            />  
            <FormInput
            type="date"
            id="maturitydate"
            name="maturitydate"
            label=" Maturity Date" 
            fluid={true}
            value={PaymentDetails?.maturitydate }
            onChange={handlechange}
            />  
          </FormGroup>
          
          <FormGroup widths="equal">
          <FormInput
            type="number"
            id="recievedinstallment"
            name="recievedinstallment"
            placeholder="0"
            label=" Recieved Installment" 
            fluid={true}
            value={PaymentDetails?.recievedinstallment }
            onChange={handlechange}
            />  
            <FormInput
            type="number"
            id="pendinginstallment"
            name="pendinginstallment"
              label="Pending Installment"
            placeholder="0"  
            fluid={true}
            value={PaymentDetails?.pendinginstallment }
            onChange={handlechange}
            />  
          </FormGroup>
          
          <FormInput
            type="text"
            id="cusname"
            name="cusname"
            placeholder="Name"
            label=" Customer Name" 
            fluid={true}
            readOnly={true}
            value={PaymentDetails?.cusname }
            onChange={handlechange}
            />  

           <FormGroup widths="equal">
            <FormInput
              fluid={true}
              type="number"
              value={PaymentDetails?.doornumber }
              label="Door Number"
              //  error={isValid}
              readOnly={true}
              placeholder="Door Number"
              id="doornumber"
              name="doornumber"
             onChange={handlechange}
              maxLength="70"
            />
            <FormInput
              fluid={true}
              type="text"
              value={PaymentDetails?.street }
              label="Street"
              // error={isValid}
              readOnly={true}
              placeholder="Street"
              id="street"
              name="street"
             onChange={handlechange}
              maxLength="70"
            />
          </FormGroup>

          <FormGroup widths="equal">
          <FormInput
              fluid={true}
              type="number"
              value={PaymentDetails?.pincode }
              label="Pincode"
              //     error={isValid}
              readOnly={true}
              placeholder="Pincode"
              id="pincode"
              name="pincode"
             onChange={handlechange}
              maxLength="70"
            />
          
            <FormInput
              fluid={true}
              type="tel"
              value={PaymentDetails?.mobilenumber }
              label="Mobile Number"
              //  error={isValid}
              readOnly={true}
              placeholder="Mobile Number"
              id="mobilenumber"
              name="mobilenumber"
              onChange={handlechange}
              maxLength="10"
            />
            </FormGroup>

          <FormGroup widths="equal">
            <FormInput
              type="text"
              fluid={true}
              label="Area"
              value={PaymentDetails?.area }
              placeholder="Area"
              id="area"
              name="area"
              readOnly={true}
            ////  error={isValid}
             onChange={handlechange}
              maxLength="150"
            />

            <FormInput
              type="text"
              fluid={true}
              label="Taluk"
              value={PaymentDetails?.taluk }
              placeholder="Taluk"
              id="taluk"
              name="taluk"
              readOnly={true}
           //   error={isValid}
              onChange={handlechange}
              maxLength="150"
            />
          </FormGroup>
          <FormGroup widths="equal">
            <FormInput
              type="text"
              fluid={true}
              label="City"
              value={PaymentDetails?.city }
              placeholder="City"
              id="city"
              name="city"
              readOnly={true}
           //   error={isValid}
              onChange={handlechange}
              maxLength="150"
            />

            <FormInput
              type="text"
              fluid={true}
              label="State"
              value={PaymentDetails?.state }
              placeholder="State"
              id="state"
              name="state"
              readOnly={true}
              // error={isValid}
             onChange={handlechange}
              maxLength="150"
            />
          </FormGroup>
          <FormGroup widths="equal">  
          <FormInput
            type="email"
            id="cusmail"
            name="cusmail"
            placeholder="e-mail"
            label=" Customer Mail-ID" 
              fluid={true}
              readOnly={true}
            value={PaymentDetails?.cusmail }
           onChange={handlechange}
          /> 
            <FormInput
            type="text"
            id="recievedby"
            name="recievedby"
            placeholder="Recieved By"
            label=" Recieved By" 
              fluid={true}
     
         value={PaymentDetails?.recievedby }
            onChange={handlechange}
            />
          </FormGroup>
          <Accordion fluid styled>
            
        <AccordionTitle
          active={activeIndex === 0}
          index={0}
          onClick={handleClick}>
          <Icon name='dropdown' />
          Card
        </AccordionTitle>
        <AccordionContent active={activeIndex === 0}>
        <FormInput
            type="number"
            id="creditcard"
            name="creditcard"
            placeholder="Amount"
            label=" Card" 
            fluid={true}
            value={PaymentDetails?.creditcard }
            onChange={handlechange}
          /> 
        </AccordionContent>
        <AccordionTitle
          active={activeIndex === 1}
          index={1}
         onClick={handleClick}
        >
          <Icon name='dropdown' />
          Cash
        </AccordionTitle>
        <AccordionContent active={activeIndex === 1}>
        <FormInput
            type="number"
            id="cash"
            name="cash"
            placeholder="Amount"
            label=" Cash" 
            fluid={true}
            value={PaymentDetails?.cash }
            onChange={handlechange}
          /> 
            </AccordionContent>
            <AccordionTitle
          active={activeIndex === 2}
          index={2}
         onClick={handleClick}
        >
          <Icon name='dropdown' />
          Bank
        </AccordionTitle>
        <AccordionContent active={activeIndex === 2}>
        <FormInput
            type="number"
            id="bank"
            name="bank"
            placeholder="Amount"
            label=" Bank" 
            fluid={true}
            value={PaymentDetails?.bank }
            onChange={handlechange}
          /> 
            </AccordionContent>
            
            <AccordionTitle
          active={activeIndex === 3}
          index={3}
         onClick={handleClick}
        >
          <Icon name='dropdown' />
          Old Chit
            </AccordionTitle>
            <AccordionContent active={activeIndex == 3}>
            <FormInput
            type="text"
            id="oldchit"
            name="oldchit"
            placeholder="Amount"
            label=" Old Chit" 
            fluid={true}
            value={PaymentDetails?.oldchit }
            onChange={handlechange}
          /> 
            </AccordionContent>
          
          </Accordion>
          <props.button {...props} />
        </Form>
      </Container>
    </div>
  );
}
export default PaymentMode;
