import React, { useEffect, useState } from "react";
import {
  FormInput,
  FormGroup,
  Form,
  Button,
  FormField,
  Container,
  Checkbox,
  TextArea,
  Icon,
  FormSelect,
  Label,
  FormTextArea,
} from "semantic-ui-react";
import { SuccessToast } from "../utils/ProjectToast";
import Customspinner from "../components/Customspinner.tsx";
import { ToastContainer } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { type BranchDetail } from "../lib/dbobjectmodel";
import { BranchInitialState } from "../lib/initialState.ts";

function BranchDetails(props: any) {
  let { state } = useLocation();
  const navigate = useNavigate();
  const [isValid, setIsValid] = useState(false);
  const [branchDetails, setBranchDetails] =
    useState<BranchDetail>(BranchInitialState);
  const [spinners, setspinners] = useState(false);
  const [Buttontext, setButtonText] = useState("save");

  useEffect(() => {
    props.onTrigger(process.env.REACT_APP_GET_URL_BRANCH);
  }, []);

  useEffect(() => {
    if (state) {
      let _data: BranchDetail = JSON.parse(state?.data);
      setTimeout(() => {
        setBranchDetails(_data);
      }, 500);
    }
  }, [state?.data]);

  useEffect(() => {
    setBranchDetails(BranchInitialState);
    props.onReset(false);
    props.onTrigger(process.env.REACT_APP_GET_POST_BRANCH);
  }, [props.reset]);

  return (
    <div className="content">
      <Container className="project-form">
        <Form>
          <FormGroup widths="equal">
            <FormInput
              type="text"
              value={branchDetails?.companyname}
              id="companyname"
              name="companyname"
              error={isValid}
              fluid={true}
              label="Company Name"
              placeholder="Enter Company Name"
            />

            <FormInput
              type="text"
              value={branchDetails?.legalname}
              id="legalname"
              name="legalname"
              error={isValid}
              fluid={true}
              label="Legal Name"
              placeholder="Enter Legal Name"
            />
          </FormGroup>

          <FormGroup widths="equal">
            <FormInput
              type="text"
              value={branchDetails?.branchcode}
              id="branchcode"
              name="branchcode"
              error={isValid}
              fluid={true}
              label="Branch Code"
              placeholder="Enter the branch Code"
            />
            <FormInput
              type="text"
              value={branchDetails?.branchname}
              id="branchname"
              name="branchname"
              error={isValid}
              fluid={true}
              label="Branch Name"
              placeholder="Enter the branch"
            />
          </FormGroup>

          <FormGroup widths="equal">
            <FormInput
              type="number"
              value={branchDetails?.branchdoornumber}
              id="branchdoornumber"
              name="branchdoornumber"
              error={isValid}
              fluid={true}
              label="Door Number "
              placeholder="Door Number"
            />
            <FormInput
              type="text"
              value={branchDetails?.street}
              id="street"
              name="street"
              error={isValid}
              fluid={true}
              label="Street"
              placeholder="Street"
            />
          </FormGroup>
          <FormGroup widths="equal">
            <FormInput
              type="text"
              value={branchDetails?.area}
              id="area"
              name="arae"
              error={isValid}
              fluid={true}
              label="Area"
              placeholder="Area"
            />
            <FormInput
              type="text"
              value={branchDetails?.taluk}
              id="taluk"
              name="taluk"
              error={isValid}
              fluid={true}
              label="Taluk"
              placeholder="Taluk"
            />
          </FormGroup>

          <FormGroup widths="equal">
            <FormInput
              type="text"
              value={branchDetails?.city}
              id="city"
              name="city"
              error={isValid}
              fluid={true}
              label="City"
              placeholder="City"
            />
            <FormInput
              type="text"
              value={branchDetails?.state}
              id="state"
              name="state"
              error={isValid}
              fluid={true}
              label="State"
              placeholder="State"
            />
          </FormGroup>

          <FormGroup widths="equal">
            <FormInput
              type="text"
              value={branchDetails?.country}
              id="country"
              name="country"
              error={isValid}
              fluid={true}
              label="Country"
              placeholder="Country"
            />
            <FormInput
              type="text"
              value={branchDetails?.pincode}
              id="pincode"
              name="pincode"
              error={isValid}
              fluid={true}
              label="Pincode"
              placeholder="Pincode"
            />
          </FormGroup>

          <FormGroup widths="equal">
            <FormInput
              type="number"
              value={branchDetails?.mobilenumber}
              id="mobilenumber"
              name="mobilenumber"
              error={isValid}
              fluid={true}
              label="Mobile Number"
              placeholder="Mobile Number"
            />
            <FormInput
              type="number"
              value={branchDetails?.landlinenumber}
              id="landlinenumber"
              name="landlinenumber"
              error={isValid}
              fluid={true}
              label="Landline Number"
              placeholder="Landline Number"
            />
          </FormGroup>
          <FormGroup widths="equal">
            <FormInput
              fluid={true}
              type="text"
              value={branchDetails?.faxnumber}
              label="Fax Number"
              error={props.isValid}
              placeholder="Fax Number"
              id="faxnumber"
              name="faxnumber"
              maxLength="10"
            />
            <FormInput
              fluid={true}
              type="text"
              value={branchDetails?.gstn}
              label="GSTN"
              error={props.isValid}
              placeholder="GSTN"
              id="gstn"
              name="gstn"
              maxLength="10"
            />
          </FormGroup>

          <FormGroup widths="equal">
            <FormInput
              fluid={true}
              type="text"
              value={branchDetails?.tan}
              label="TAN"
              placeholder="TAN"
              id="tan"
              name="tan"
              maxLength="10"
            />

            <FormInput
              fluid={true}
              type="text"
              value={branchDetails?.cin}
              label="CIN"
              placeholder="CIN"
              id="cin"
              name="cin"
              maxLength="10"
            />
          </FormGroup>
          <FormGroup widths="equal">
            <FormInput
              fluid={true}
              type="text"
              value={branchDetails?.pan}
              label="PAN"
              placeholder="PAN"
              id="pan"
              name="pan"
              maxLength="10"
            />

            <FormInput
              fluid={true}
              type="text"
              value={branchDetails?.msme}
              label="MSME"
              placeholder="MSME"
              id="msme"
              name="msme"
              maxLength="10"
            />
          </FormGroup>
          {spinners ? <Customspinner text="Saving.." /> : ""}
        </Form>
      </Container>
      <ToastContainer />
    </div>
  );
}
export default BranchDetails;
