import React, { useContext, useEffect, useState } from "react";
import {
  FormInput,
  FormGroup,
  Form,
  Button,
  FormField,
  Container,
  Checkbox,
  TextArea,
  Icon,
  FormSelect,
} from "semantic-ui-react";
import "react-toastify/dist/ReactToastify.css";
import { Dropdowns, type ConcernRoleDetail } from "../lib/dbobjectmodel";
import { getDropdown, Roles } from "../utils/common.tsx";
import { ConcernUserInitialState } from "../lib/initialState.ts";
import { useLocation, useNavigate } from "react-router-dom";
import { useGlobalContext } from "../lib/types.ts";
import { ToastContainer } from "react-toastify";

function ConcernUserRoles(props: any) {
  let { state } = useLocation();
  const [ConcernUserroledetails, setConcernUserroledetails] =
    useState<ConcernRoleDetail>(ConcernUserInitialState);
  const { sharedData } = useContext(useGlobalContext);

  const [concerns, setConcerns] = useState<Dropdowns[]>([]);

  const handleChange = (e: any, actionMeta: any) => {
    if (e.target.type === "text") {
      setConcernUserroledetails({
        ...ConcernUserroledetails,
        [e.target.name]: e.target.value,
      });
    } else {
      setConcernUserroledetails({
        ...ConcernUserroledetails,
        [actionMeta.name]: actionMeta.value,
      });
    }
  };

  useEffect(() => {
    props.onTrigger(process.env.REACT_APP_GET_POST_CONCERNUSERROLE);
    setConcerns(getDropdown("concern", sharedData));
  }, []);

  useEffect(() => {
    if (state) {
      let _data: ConcernRoleDetail = JSON.parse(state?.data);
      _data.insert_update = 2;
      setTimeout(() => {
        setConcernUserroledetails(_data);
        props.onTrigger(process.env.REACT_APP_GET_PUT_CONCERNUSERROLE);
      }, 500);
    }
  }, [state?.data]);

  useEffect(() => {
    props.onProcessdata(ConcernUserroledetails);
  });

  useEffect(() => {
    setConcernUserroledetails(ConcernUserInitialState);
    props.onReset(false);
    props.onTrigger(process.env.REACT_APP_GET_POST_CONCERNUSERROLE);
  }, [props.reset]);

  return (
    <div className="content">
      <Container className="project-form">
        <Form>
          <FormGroup widths="equal">
            <FormSelect
              value={ConcernUserroledetails?.concern}
              id="concern"
              name="concern"
              error={props.isValid}
              onChange={handleChange}
              fluid={true}
              label="Concerns"
              placeholder="Select Concerns"
              search
              scrolling
              options={concerns}
            />
          </FormGroup>
          <FormField>
            <FormSelect
              type="text"
              value={ConcernUserroledetails?.userrole}
              id="userrole"
              name="userrole"
              error={props.isValid}
              onChange={handleChange}
              fluid={true}
              label="UserRole"
              placeholder="Select UserRole"
              options={Roles}
            />
          </FormField>
          <FormGroup widths="equal">
            <div className="field">
              <span className="d-f-c ">Active</span>
              <div className="radio-input_cs">
                <label>
                  <input
                    value="Yes"
                    checked={ConcernUserroledetails?.isdeleted == "Yes"}
                    onChange={(e) => {
                      setConcernUserroledetails({
                        ...ConcernUserroledetails,
                        ["isdeleted"]: "Yes",
                      });
                    }}
                    name="gift"
                    id="giftyes"
                    type="radio"
                  />
                  <span>Yes</span>
                </label>
                <label>
                  <input
                    value="No"
                    checked={ConcernUserroledetails?.isdeleted == "No"}
                    onChange={(e) => {
                      setConcernUserroledetails({
                        ...ConcernUserroledetails,
                        ["isdeleted"]: "No",
                      });
                    }}
                    name="gift"
                    id="giftno"
                    type="radio"
                  />
                  <span>No</span>
                </label>
                <span className="selection_cs"></span>
              </div>
            </div>
          </FormGroup>
          <props.button {...props} />
        </Form>
        <ToastContainer />
      </Container>
    </div>
  );
}
export default ConcernUserRoles;
