import React, { useContext, useEffect, useState } from "react";
import {
  FormInput,
  FormGroup,
  Form,
  Button,
  FormField,
  Container,
  Checkbox,
  TextArea,
  Icon,
  FormSelect,
} from "semantic-ui-react";
import Customspinner from "../components/Customspinner.tsx";
import { ToastContainer } from "react-toastify";
import {
  ChitGiftSchemeDetail,
  Dropdowns,
  type ChitGiftDetail,
} from "../lib/dbobjectmodel";
import {
  ChitGiftInitialState,
  ChitGiftSchemeDetailInitialState,
} from "../lib/initialState.ts";
import { useLocation, useNavigate } from "react-router-dom";
import { getDropdown, GiftType } from "../utils/common.tsx";
import { useGlobalContext } from "../lib/types.ts";
import { HttpGet, useFetch } from "../lib/useFetch.tsx";

function ChitGift(props: any) {
  let { state } = useLocation();
  const [ChitDetails, setChitDetails] =
    useState<ChitGiftDetail>(ChitGiftInitialState);
  const { sharedData } = useContext(useGlobalContext);
  const [custCardNumber, setCustCardNumber] = useState<Dropdowns[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [carnumber, setCarnumber] = useState<number>(0);

  const handleChange = (e: any, actionMeta: any) => {
    const { type, name, value } = actionMeta;
    if (e.target.type === "text") {
      setChitDetails({
        ...ChitDetails,
        [e.target.name]: e.target.value,
      });
    } else if (type == "select") {
      setChitDetails({
        ...ChitDetails,
        [name]: value,
      });

      if (name === "cardnumber") {
        setCarnumber(value);
        setIsLoading(true);
        HttpGet(process.env.REACT_APP_GET_GIFT_SCHEME_DETAILS, value).then(
          (res: ChitGiftSchemeDetail) => {
            setTimeout(() => {
              setChitDetails({
                ...ChitDetails,
                ["cardnumber"]: carnumber,
              });
              const data: ChitGiftDetail = res[0];
              console.log(data);

              setChitDetails({
                ...ChitDetails,
                cardnumber: value,
                groupnumber: data?.groupnumber,
                cusname: data?.cusname,
                groupname: data?.groupname,
                installment: data?.installment,
                giftvalue: data?.giftvalue,
                CreatedBy: 0,
                ModifiedBy: 0,
                isdeleted: false,
                deletedBy: 0,
                insert_update: 1,
                giftid: 0,
              });
              setIsLoading(false);
            }, 500);
          }
        );
      }
    }
  };

  useEffect(() => {
    props.onTrigger(process.env.REACT_APP_GET_POST_CHITGIFT);
    setCustCardNumber(getDropdown("customer", sharedData));
  }, []);

  useEffect(() => {
    if (state) {
      let _data: ChitGiftDetail = JSON.parse(state?.data);
      _data.insert_update = 2;
      setTimeout(() => {
        setChitDetails(_data);
        props.onTrigger(process.env.REACT_APP_GET_PUT_CHITGIFT);
      }, 500);
    }
  }, [state?.data]);

  useEffect(() => {
    props.onProcessdata(ChitDetails);
  });

  useEffect(() => {
    setChitDetails(ChitGiftInitialState);
    props.onReset(false);
    props.onTrigger(process.env.REACT_APP_GET_POST_CHITGIFT);
  }, [props.reset]);

  return (
    <div className="content">
      <Container className="project-form">
        <Form>
          <FormGroup widths="equal">
            <FormSelect
              type="select"
              value={ChitDetails?.cardnumber}
              id="cardnumber"
              name="cardnumber"
              error={ChitDetails?.cardnumber == "" ? props.isValid : false}
              onChange={handleChange}
              fluid={true}
              label="Customer Card Number"
              placeholder="Type or select card number"
              options={custCardNumber}
              search
              scrolling
              loading={isLoading}
              disabled={state?.data}
            />
            <FormInput
              type="number"
              id="groupnumber"
              value={ChitDetails?.groupnumber}
              name="groupnumber"
              style={{ opacity: "0.6" }}
              fluid={true}
              label="Customer Group Number"
              placeholder="Customer Group Number"
              readOnly={true}
            />
          </FormGroup>
          <FormGroup widths="equal">
            <FormInput
              type="text"
              fluid={true}
              label="Customer Name"
              value={ChitDetails?.cusname}
              placeholder="Customer Name"
              id="cusname"
              name="cusname"
              style={{ opacity: "0.6" }}
              maxLength="150"
            />
            <FormInput
              type="text"
              id="barcode"
              error={ChitDetails?.barcode == "" ? props.isValid : false}
              value={ChitDetails?.barcode}
              name="barcode"
              onChange={handleChange}
              fluid={true}
              label="Gift Barcode"
              placeholder="Gift Barcode"
            />
          </FormGroup>

          <FormGroup widths="equal">
            <FormInput
              type="text"
              fluid={true}
              label="Group Name"
              value={ChitDetails?.groupname}
              placeholder="Group Name"
              id="groupname"
              name="groupname"
              style={{ opacity: "0.6" }}
              maxLength="150"
            />
            <FormInput
              type="number"
              id="giftbarvalue"
              iconPosition="left"
              icon="rupee"
              value={ChitDetails?.giftvalue}
              name="giftbarvalue"
              style={{ opacity: "0.6" }}
              fluid={true}
              label="Gift Value"
              placeholder="Gift Value"
            />
          </FormGroup>
          <FormInput
            type="text"
            fluid={true}
            label="Installment"
            value={ChitDetails?.installment}
            placeholder="Installment"
            id="installment"
            name="installment"
            style={{ opacity: "0.6" }}
            maxLength="150"
            iconPosition="left"
            icon="rupee"
          />
          <FormSelect
            fluid={true}
            label="Gift Type"
            value={ChitDetails?.gifttype}
            placeholder="Gift Type"
            id="gifttype"
            name="gifttype"
            onChange={handleChange}
            maxLength="150"
            options={GiftType}
            error={ChitDetails?.gifttype == "" ? props.isValid : false}
            search
            type="select"
          />
          <props.button {...props} />
        </Form>
        <ToastContainer />
      </Container>
    </div>
  );
}
export default ChitGift;
