import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Nav, Collapse } from "reactstrap";
import PerfectScrollbar from "perfect-scrollbar";
import logo from "../../assets/img/logo.png";

import { Icon } from "semantic-ui-react";
var ps;
function Sidebar(props) {
  const location = useLocation();
  const sidebar = React.useRef();
  const [currentRole, setRole] = useState([]);
  const [collapse, setCollapse] = useState(false);

  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(sidebar.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  });

  useEffect(() => {
    // if (currentRole.length === 0) {
    //   setRole(JSON.parse(sessionStorage.getItem("menus")));
    // }
  });

  const menutoggle = () => {
    setCollapse(!collapse);
  };

  return (
    <div
      className="sidebar"
      data-color="#2c662c"
      data-active-color={props.activeColor}
    >
      <div className="logo">
        <a href="#" className="simple-text logo-mini">
          <div className="logo-img">
            <img src={logo} />
          </div>
        </a>
        <a className="simple-text logo-normal">CHIT management</a>
      </div>
      <div className="sidebar-wrapper" ref={sidebar}>
        {props.routes.map((prop, key) => {
          return (
            <Nav key={key}>
              <li
                className={
                  activeRoute(prop.path) + (prop.pro ? " active-pro" : "")
                }
                key={key}
              >
                <NavLink to={prop.layout + prop.path} className="nav-NavLink">
                  <Icon name={prop.icon} />
                  <span>{prop.name}</span>
                </NavLink>
              </li>
            </Nav>
          );
        })}

        <Nav key={57878}>
          <li>
            <a onClick={menutoggle}>
              <p>
                Views<b class="caret"></b>
              </p>
            </a>
            <Collapse isOpen={collapse}>
              <Nav key={64645}>
                {props.viewroutes.map((prop, key) => {
                  return (
                    <li
                      className={
                        activeRoute(prop.path) + (prop.pro ? " active-pro" : "")
                      }
                      key={key}
                    >
                      <NavLink
                        to={prop.layout + prop.path}
                        className="nav-NavLink"
                      >
                        <Icon name={prop.icon} />
                        <span>{prop.name}</span>
                      </NavLink>
                    </li>
                  );
                })}
              </Nav>
            </Collapse>
          </li>
        </Nav>
      </div>
    </div>
  );
}

export default Sidebar;
