import React, { useState, useEffect } from "react";
import {
  FormInput,
  FormGroup,
  Form,
  Container,
  FormSelect,
  Label,
  FormField,
} from "semantic-ui-react";
import { useLocation, useNavigate } from "react-router-dom";
import { type Concerns } from "../lib/dbobjectmodel";
import { CompanyType } from "../utils/common.tsx";
import { ConcernInitialState } from "../lib/initialState.ts";
import { maxLengthCheck, onlyString } from "../utils/ProjectToast.js";
import { ToastContainer } from "react-toastify";

function Concern(props: any) {
  let { state } = useLocation();
  const [concern, setConcern] = useState<Concerns>(ConcernInitialState);
  const [companytypes] = useState(CompanyType);

  const handleChange = (e: any, actionMeta: any) => {
    const { value, name } = e.target;
    let regex = /^[A-Za-z\s]+$/;
    if (e.target.type === "text") {
      let _value: string;
      switch (name) {
        case "tan":
          _value = value.toUpperCase();
          break;
        case "gstn":
          _value = value.toUpperCase();
          break;
        case "cin":
          _value = value.toUpperCase();
          break;
        case "pan":
          _value = value.toUpperCase();
          break;
        case "msme":
          _value = value.toUpperCase();
          break;
        case "street":
        case "taluk":
        case "city":
        case "state":
        case "companyname":
        case "ownerpartnername":
        case "area":
          if (value != "" && value.match(regex)) _value = value;
          else {
            setConcern({ ...concern, [e.target.name]: value.slice(0, -1) });
            return;
          }
          console.log(value.match(regex));

          break;
        default:
          _value = value;
          break;
      }
      setConcern({ ...concern, [e.target.name]: _value });
    } else if (e.target.type === "number" && e.target.value >= 0) {
      setConcern({ ...concern, [e.target.name]: e.target.value });
    } else if (actionMeta.type === "option") {
      setConcern({ ...concern, [actionMeta.name]: actionMeta.value });
    }
  };

  useEffect(() => {
    props.onTrigger(process.env.REACT_APP_GET_POST_CONCERN);
  }, []);

  useEffect(() => {
    if (state) {
      let _data: Concerns = JSON.parse(state?.data);
      _data.insert_update = 2;
      setTimeout(() => {
        setConcern(_data);
        props.onTrigger(process.env.REACT_APP_GET_PUT_CONCERN);
      }, 500);
    }
  }, [state?.data]);

  useEffect(() => {
    props.onProcessdata(concern);
  });

  useEffect(() => {
    setConcern(ConcernInitialState);
    props.onReset(false);
    props.onTrigger(process.env.REACT_APP_GET_POST_CONCERN);
    window.history.replaceState({}, "");
  }, [props.reset]);

  return (
    <div className="content">
      <Container className="project-form">
        <Form>
          <FormGroup widths="equal">
            <FormInput
              type="text"
              id="companycode"
              value={concern?.companycode}
              name="companycode"
              fluid={true}
              label="Companycode"
              placeholder="Enter Companycode"
              error={concern?.companycode == "" ? props.isValid : false}
              onChange={handleChange}
              maxLength="7"
            />
            <FormInput
              type="text"
              id="companyname"
              value={concern?.companyname}
              name="companyname"
              fluid={true}
              label="Companyname"
              placeholder="Enter Companyname"
              error={concern?.companyname == "" ? props.isValid : false}
              onChange={handleChange}
              maxLength="150"
              // onInput={onlyString}
              // pattern="[^a-zA-Z]"
            />
          </FormGroup>
          <FormGroup widths="equal">
            <FormSelect
              type="option"
              fluid={true}
              value={concern?.companytype}
              label="Company Type"
              error={concern?.companytype == "" ? props.isValid : false}
              id="companytype"
              name="companytype"
              onChange={handleChange}
              maxLength="20"
              options={companytypes}
            />

            <FormInput
              fluid={true}
              type="text"
              value={concern?.ownerpartnername}
              label="Owner/Partner Name"
              error={concern?.ownerpartnername == "" ? props.isValid : false}
              placeholder="Owner/Partner Name"
              id="ownerpartnername"
              name="ownerpartnername"
              onChange={handleChange}
              maxLength="100"
            />
          </FormGroup>
          <FormGroup widths="equal">
            <FormInput
              fluid={true}
              type="number"
              value={concern?.doornumber}
              label="Door Number"
              error={concern?.doornumber == "" ? props.isValid : false}
              placeholder="Door Number"
              id="doornumber"
              name="doornumber"
              onChange={handleChange}
              maxLength="10"
              onInput={maxLengthCheck}
            />
            <FormInput
              fluid={true}
              type="text"
              value={concern?.street}
              label="Street"
              error={concern?.street == "" ? props.isValid : false}
              placeholder="Street"
              id="street"
              name="street"
              onChange={handleChange}
              maxLength="150"
            />
          </FormGroup>
          <FormGroup widths="equal">
            <FormInput
              type="text"
              fluid={true}
              label="Area"
              value={concern?.area}
              placeholder="Area"
              id="area"
              name="area"
              error={concern?.area == "" ? props.isValid : false}
              onChange={handleChange}
              maxLength="150"
            />

            <FormInput
              type="text"
              fluid={true}
              label="Taluk"
              value={concern?.taluk}
              placeholder="Taluk"
              id="taluk"
              name="taluk"
              error={concern?.taluk == "" ? props.isValid : false}
              onChange={handleChange}
              maxLength="150"
            />
          </FormGroup>
          <FormGroup widths="equal">
            <FormInput
              type="text"
              fluid={true}
              label="City"
              value={concern?.city}
              placeholder="City"
              id="city"
              name="city"
              error={concern?.city == "" ? props.isValid : false}
              onChange={handleChange}
              maxLength="150"
            />

            <FormInput
              type="text"
              fluid={true}
              label="State"
              value={concern?.state}
              placeholder="State"
              id="state"
              name="state"
              error={concern?.state == "" ? props.isValid : false}
              onChange={handleChange}
              maxLength="100"
            />
          </FormGroup>
          <FormGroup widths="equal">
            <FormInput
              type="text"
              fluid={true}
              label="Country"
              value={concern?.country}
              placeholder="Country"
              id="country"
              name="country"
              readOnly={true}
              onChange={handleChange}
              maxLength="50"
            />
            <FormInput
              fluid={true}
              type="number"
              value={concern?.pincode}
              label="Pincode"
              error={concern?.pincode == "" ? props.isValid : false}
              placeholder="Pincode"
              id="pincode"
              name="pincode"
              onChange={handleChange}
              onInput={maxLengthCheck}
              maxLength="6"
            />
          </FormGroup>
          <FormGroup widths="equal">
            <FormInput
              fluid={true}
              type="number"
              value={concern?.mobilenumber}
              label="Mobile Number"
              error={concern?.mobilenumber == "" ? props.isValid : false}
              placeholder="Mobile Number"
              id="mobilenumber"
              name="mobilenumber"
              onChange={handleChange}
              onInput={(e) => {
                maxLengthCheck(e);
              }}
              maxLength="10"
            />

            <FormInput
              fluid={true}
              type="text"
              value={concern?.landlinenumber}
              label="Landline Number"
              error={concern?.landlinenumber == "" ? props.isValid : false}
              placeholder="Landline Number"
              id="landlinenumber"
              name="landlinenumber"
              onChange={handleChange}
              onInput={maxLengthCheck}
              maxLength="12"
            />
          </FormGroup>

          <FormGroup widths="equal">
            <FormInput
              fluid={true}
              type="text"
              value={concern?.faxnumber}
              label="Fax Number"
              error={concern?.faxnumber == "" ? props.isValid : false}
              placeholder="022-12345678"
              id="faxnumber"
              name="faxnumber"
              onChange={handleChange}
              maxLength="12"
            />
            <FormField>
              <FormInput
                fluid={true}
                type="text"
                value={concern?.gstn}
                label="GSTN"
                error={concern?.gstn == "" ? props.isValid : false}
                placeholder="22AAAAA0000A1Z5"
                id="gstn"
                name="gstn"
                onChange={handleChange}
                maxLength="15"
              ></FormInput>
              {concern?.gstn != "" && props.isFormatted.includes("GSTN") ? (
                <Label basic color="red" pointing="above">
                  Please enter valid GSTN number
                </Label>
              ) : (
                ""
              )}
            </FormField>
          </FormGroup>

          <FormGroup widths="equal">
            <FormField>
              <FormInput
                fluid={true}
                type="text"
                value={concern?.tan}
                label="TAN"
                placeholder="RAJM99999B"
                id="tan"
                name="tan"
                onChange={handleChange}
                maxLength="10"
              />
              {concern?.tan != "" && props.isFormatted.includes("TAN") ? (
                <Label basic color="red" pointing="above">
                  Please enter valid TAN number
                </Label>
              ) : (
                ""
              )}
            </FormField>
            <FormField>
              <FormInput
                fluid={true}
                type="text"
                value={concern?.cin}
                label="CIN"
                placeholder="L01631KA2010PTC096843"
                id="cin"
                name="cin"
                onChange={handleChange}
                maxLength="21"
              />
              {concern?.cin != "" && props.isFormatted.includes("CIN") ? (
                <Label basic color="red" pointing="above">
                  Please enter valid CIN number
                </Label>
              ) : (
                ""
              )}
            </FormField>
          </FormGroup>
          <FormGroup widths="equal">
            <FormField>
              <FormInput
                fluid={true}
                type="text"
                value={concern?.pan}
                label="PAN"
                error={concern?.pan == "" ? props.isValid : false}
                placeholder="AAAAA5284N"
                id="pan"
                name="pan"
                onChange={handleChange}
                maxLength="10"
              />
              {concern?.pan != "" && props.isFormatted.includes("PAN") ? (
                <Label basic color="red" pointing="above">
                  Please enter valid PAN number
                </Label>
              ) : (
                ""
              )}
            </FormField>
            <FormInput
              fluid={true}
              type="text"
              value={concern?.msme}
              label="MSME"
              error={concern?.msme == "" ? props.isValid : false}
              placeholder="MSME"
              id="msme"
              name="msme"
              onChange={handleChange}
              maxLength="50"
            />
          </FormGroup>

          <props.button {...props} />
        </Form>
        <ToastContainer />
      </Container>
    </div>
  );
}

export default Concern;
