import React, { useContext, useEffect, useState } from "react";
import {
  FormInput,
  FormGroup,
  Form,
  Button,
  FormField,
  Container,
  Checkbox,
  Icon,
  FormSelect,
  Label,
} from "semantic-ui-react";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Customspinner from "../components/Customspinner.tsx";
import { maxLengthCheck } from "../utils/ProjectToast";
import { Utility } from "../utils/Utility";
import { Dropdowns, type GroupDetail } from "../lib/dbobjectmodel";
import { GroupinitialState } from "../lib/initialState.ts";
import { useGlobalContext } from "../lib/types.ts";
import { getDropdown } from "../utils/common.tsx";

function GroupDetails(props: any) {
  let { state } = useLocation();
  const navigate = useNavigate();
  const [groupDetails, setGroupDetails] =
    useState<GroupDetail>(GroupinitialState);
  const [branchs, setBranchs] = useState<Dropdowns[]>([]);
  const [isValid, setIsValid] = useState(false);
  const { sharedData } = useContext(useGlobalContext);

  const handleChange = (e: any, actionMeta: any) => {
    if (e.target.type === "text") {
      setGroupDetails({ ...groupDetails, [e.target.name]: e.target.value });
    } else {
      setGroupDetails({ ...groupDetails, [actionMeta.name]: actionMeta.value });
    }
  };

  useEffect(() => {
    props.onTrigger(process.env.REACT_APP_GET_URL_GROUP);
    setBranchs(getDropdown("branch", sharedData));
  }, []);

  useEffect(() => {
    if (state) {
      let _data: GroupDetail = JSON.parse(state?.data);
      _data.startdate = new Date(
        _data?.startdate.split("-").reverse().join("-")
      )
        .toISOString()
        .split("T")[0];
      _data.enddate = new Date(_data?.enddate.split("-").reverse().join("-"))
        .toISOString()
        .split("T")[0];
      _data.insert_update = 2;
      setTimeout(() => {
        setGroupDetails(_data);
        props.onTrigger(process.env.REACT_APP_GET_PUT_GROUP);
      }, 500);
    }
  }, [state?.data]);

  useEffect(() => {
    props.onProcessdata(groupDetails);
  });

  useEffect(() => {
    setGroupDetails(GroupinitialState);
    props.onReset(false);
    props.onTrigger(process.env.REACT_APP_GET_POST_GROUP);
  }, [props.reset]);

  console.log(groupDetails);

  return (
    <div className="content">
      <Container className="project-form">
        <Form>
          <FormGroup widths="equal">
            <FormInput
              fluid={true}
              label="Serial #"
              id="serialnumber"
              name="serialnumber"
              type="number"
              value={groupDetails?.serialnumber}
              style={{ opacity: "0.6", pointerEvents: "none" }}
              readonly
            />
          </FormGroup>

          <FormGroup widths="equal">
            <FormInput
              fluid
              id="groupname"
              name="groupname"
              label="Group Name"
              type="text"
              placeholder="Enter group name"
              onInput={maxLengthCheck}
              maxLength="50"
              onChange={handleChange}
              value={groupDetails?.groupname}
              className="inline"
              style={{ opacity: "0.6", pointerEvents: "none" }}
              readonly
            />
          </FormGroup>
          {/* <FormGroup widths="equal">
            <FormSelect
              fluid
              options={branchs}
              label="Branch Name"
              id="branchname"
              name="branchname"
              multiple={true}
              error={isValid}
              placeholder="Select branch"
              value={groupDetails?.branchname}
              onChange={handleChange}
            />
          </FormGroup> */}
          <FormGroup widths="equal">
            <FormInput
              fluid={true}
              type="date"
              label="Start Date"
              error={isValid}
              id="startdate"
              name="startdate"
              style={{ opacity: "0.6", pointerEvents: "none" }}
              readonly
              tabIndex={-1}
              value={groupDetails?.startdate}
            />
            <FormInput
              fluid={true}
              type="date"
              label="End Date"
              error={isValid}
              id="enddate"
              name="enddate"
              style={{ opacity: "0.6", pointerEvents: "none" }}
              readonly
              tabIndex={-1}
              value={groupDetails?.enddate}
            />
          </FormGroup>
          <FormGroup widths="equal">
            <FormInput
              fluid={true}
              label="Installment Amount"
              id="installmentamount"
              name="installmentamount"
              type="number"
              placeholder="Enter the installment amount"
              onInput={maxLengthCheck}
              maxLength="50"
              onChange={handleChange}
              value={groupDetails?.installmentamount}
              style={{ opacity: "0.6", pointerEvents: "none" }}
              readonly
            />
          </FormGroup>
        </Form>
      </Container>
    </div>
  );
}

export default GroupDetails;
