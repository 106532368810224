import React, { useEffect, useState } from "react";
import CustomTable from "../../components/Table/table.tsx";
import { TableHeaders } from "../../components/Table/tableheader";
import Customspinner from "../../components/Customspinner.tsx";
import { useFetch } from "../../lib/useFetch.tsx";
import { SchemeDetail } from "../../lib/dbobjectmodel";
import { SchemeInitalState } from "../../lib/initialState.ts";

function ViewSchemes() {
  const [schemes, setSchemes] = useState<SchemeDetail[]>();

  const { status, statusText, data, error, loading } = useFetch<SchemeDetail[]>(
    process.env.REACT_APP_GET_SCHEMES,
    [],
    SchemeInitalState
  );

  useEffect(() => {
    setSchemes(data);
  }, [status == 200]);

  return (
    <>
      {!loading ? (
        <div className="content">
          <CustomTable
            datas={schemes || []}
            headers={TableHeaders.schemeheader}
            isedit={true}
            isdelete={true}
            iswhatapp={false}
            issms={false}
            iscustomer={false}
            isdefault={false}
            isapprove={false}
            isfilter={true}
            navigateurl="/admin/schemes"
            deleteurl={process.env.REACT_APP_DELETE_SCHEME}
          />
        </div>
      ) : loading ? (
        <Customspinner text="Loading.." />
      ) : (
        ""
      )}
    </>
  );
}

export default ViewSchemes;
