import React, { useEffect, useState } from "react";
import CustomTable from "../../components/Table/table.tsx";
import { TableHeaders } from "../../components/Table/tableheader.js";
import Customspinner from "../../components/Customspinner.tsx";
import { BranchInitialState } from "../../lib/initialState.ts";
import { BranchDetail } from "../../lib/dbobjectmodel.ts";
import { useFetch } from "../../lib/useFetch.tsx";

function ViewBranches() {
  const [branchs, setBranchs] = useState<BranchDetail[]>();

  const { status, statusText, data, error, loading } = useFetch<BranchDetail[]>(
    process.env.REACT_APP_GET_URL_BRANCH,
    [],
    BranchInitialState
  );

  useEffect(() => {
    setBranchs(data);
  }, [status == 200]);

  return (
    <>
      {!loading ? (
        <div className="content">
          <CustomTable
            datas={branchs || []}
            headers={TableHeaders.viewBranchs}
            isedit={true}
            isdelete={false}
            iswhatapp={false}
            issms={false}
            iscustomer={false}
            isdefault={false}
            isapprove={false}
            isfilter={true}
            navigateurl="/admin/branch"
          />
        </div>
      ) : loading ? (
        <Customspinner text="Loading.." />
      ) : (
        ""
      )}
    </>
  );
}

export default ViewBranches;
